// Lib
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
// Api
import {
  useDeleteFoodLibraryProductMutation,
  useGetFoodLibraryQuery,
} from "rtkQuery/query/foodLibraryAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
import { TableAction } from "types/common";
import { FoodLibraryProduct } from "types/foodLibrary";
// Theme
import { theme } from "theme";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import {
  DescriptionBlackIcon,
  PlusIcon,
  RightBurgerMenuIcon,
  TrashIcon,
} from "icons";
// Components
import { DropDown, Pagination, Table } from "components";
import { ConfirmDialog } from "components/Modals";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";

import { columns, filtersList } from "./config";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";

export const FoodLibrary: FC = () => {
  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const {
    page,
    limit,
    sortBy,
    sortingOrder,
    selectedFilters,
    debouncedFiltersQuery,
    setPage,
    setLimit,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({
    name: ETable.FoodLibrary,
  });

  const [remove, { isLoading: isProductDeleteLoading }] =
    useDeleteFoodLibraryProductMutation();

  const { data, isFetching, error } = useGetFoodLibraryQuery(
    {
      query: {
        page,
        limit,
        sortBy,
        sortingOrder,
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
      },
    },
    rtkQueryParams,
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const [deleteProductModal, setDeleteProductModal] = useState<
    false | FoodLibraryProduct
  >(false);

  const handleAddProduct = () => {
    navigate(`${ADMIN_ROUTES.FOOD_LIBRARY_CREATE.path}`);
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      onClick: (row: FoodLibraryProduct) => setDeleteProductModal(row),
    },
  ];

  const onConfirmDelete = async () => {
    if (!deleteProductModal) return;

    if (!deleteProductModal?.id) return;

    try {
      await remove({ id: deleteProductModal.id }).unwrap();

      openNotification({
        message: NOTIFICATIONS.PRODUCT_DELETED,
      });

      setDeleteProductModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleTableChange = useCallback(
    (
      _pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue>,
      sorter: SorterResult<FoodLibraryProduct>,
    ) => {
      handleSort(sorter?.field as string, sorter?.order);
    },
    [],
  );

  const dropDownMenuItems = [
    {
      key: "1",
      label: "Add Prroduct",
      onClick: handleAddProduct,
    },
  ];

  const onRow = (record: FoodLibraryProduct) => {
    return {
      onClick: () => {
        navigate(`${ADMIN_ROUTES.FOOD_LIBRARY.path}/${record.id}`);
      },
    };
  };
  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Food Library</Typography.H1>
            {isDesktop ? (
              <Button.Heading
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={handleAddProduct}
              >
                Add Product
              </Button.Heading>
            ) : (
              <DropDown items={dropDownMenuItems} trigger={["click"]}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            )}
          </FlexContainer>

          <Table
            isLoading={isFetching}
            withPagination={!isDesktop}
            dataSource={data?.items || []}
            columns={columns}
            actions={actions}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No Products to show.",
            }}
            header={{
              totalCount: data?.totalCount,
              filter: {
                filters: filtersList,
                selected: selectedFilters,
                setValue: handleSetTableFilterValues,
              },
            }}
            onRow={onRow}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          showSizeChanger={!isDesktop}
          setLimit={setLimit}
          setPage={setPage}
          totalItems={data?.totalCount}
        />
      </PageWrapper>

      <ConfirmDialog
        open={!!deleteProductModal}
        isLoading={isProductDeleteLoading}
        Icon={TrashIcon}
        message={`${
          deleteProductModal ? deleteProductModal?.name : "This Product"
        } will be deleted`}
        description="Are you sure to continue this action?"
        onCancel={() => setDeleteProductModal(false)}
        firstCTAButton={{
          title: "Delete Product",
          status: "danger",
          loading: isProductDeleteLoading,
          onClick: onConfirmDelete,
        }}
      />
    </>
  );
};
