export enum SettingsListOption {
  service = "service",
  purpose = "purpose",
}

export type AuthSettingsResponseDto = {
  allowAccountActivation: boolean;
  isApplePayAllowed: boolean;
  isFoodLibraryEnabled: boolean;
  isAppAccessible: boolean;
  isTabbyAllowed: boolean;
  isTalyAllowed: boolean;
  isStockActive: boolean;
  isTamaraAllowed: boolean;
  requireInvitation: boolean;
  forceUpdateVersion: string;
  releaseVersion: string;
  isWaitList: boolean;
  allowMenuItemsGrid: boolean;
  isTrialPackageAllowed: boolean;
  isExternalFiatCentsPriceVisible: boolean;
  isComments: boolean;
  instantOrderCancellationTime: number;
  defaultDeliveryTime: number;
  defaultPreparationTime: number;
  coupon: {
    isCoupon: boolean;
    isCouponTopUp: boolean;
  };
  scheduleOrder: {
    orderCancelTime: number;
    scheduleOrderTime: number;
    cancelTimeAfterStart: number;
    timeToNextSlot: number;
    isScheduleOrders: number;
  };
};

export type SetSettingsRequestDto = {
  allowAccountActivation?: boolean;
  requireInvitation?: boolean;
  isWaitList?: boolean;
  allowMenuItemsGrid?: boolean;
  isApplePayAllowed?: boolean;
  isAppAccessible?: boolean;
  isTrialPackageAllowed?: boolean;
};

export type UpdatePermissionRequestDto = {
  id: number;
  limit: number;
};

export type PermissionsLimitResponseDto = {
  createdAt: string;
  id: string;
  limit: number;
  name: string;
  updatedAt: string;
  _domainEvents: [];
};

export type AuthSettingsListOptionResponseDto = {
  _domainEvents: [];
  id: string;
  type: SettingsListOption;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type AddAuthSettingsListOptionRequestDto = {
  name: string;
  type: SettingsListOption;
};

export type UpdateAuthSettingsListOptionRequestDto = {
  id: string;
  name: string;
  type: SettingsListOption;
};
