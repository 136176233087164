import { number, object, ObjectSchema, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import { FoodLibraryProductForm } from "./types";

const schema: ObjectSchema<FoodLibraryProductForm> = object({
  name: string().defined().required("Name is required"),
  calories: number()
    .typeError("Calories must be a number")
    .min(0, "Fat must be at least 0")
    .required("Calories are required"),
  carbs: number()
    .typeError("Carbs must be a number")
    .min(0, "Carbs must be at least 0")
    .required("Carbs are required"),
  fat: number()
    .typeError("Fat must be a number")
    .min(0, "Fat must be at least 0")
    .required("Fat is required"),
  protein: number()
    .typeError("Protein must be a number")
    .min(0, "Protein must be at least 0")
    .required("Protein is required"),
  servingSize: string().required("Serving size is required"),
  servingsNumber: number()
    .typeError("Servings number must be a number")
    .integer("Servings number must be an integer")
    .required("Servings number is required"),
  code: string().required("Code is required"),
});

export const resolver: Resolver<FoodLibraryProductForm> = yupResolver(
  schema,
) as Resolver<FoodLibraryProductForm>;
