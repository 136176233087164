import { NOTIFICATIONS } from "consts/notifications";

export enum Field {
  AllowAccountActivation = "allowAccountActivation",
  AllowWaitingList = "isWaitList",
  RequireInvitation = "requireInvitation",
  AllowCoupons = "isCoupon",
  AllowTopUpCoupons = "isCouponTopUp",
  AllowMenuItemsGrid = "allowMenuItemsGrid",
  IsApplePayAllowed = "isApplePayAllowed",
  IsFoodLibraryEnabled = "isFoodLibraryEnabled",
  IsAppAccessible = "isAppAccessible",
  IsTabbyAllowed = "isTabbyAllowed",
  IsTalyAllowed = "isTalyAllowed",
  IsTamaraAllowed = "isTamaraAllowed",
  IsExternalFiatCentsPriceVisible = "isExternalFiatCentsPriceVisible",
  IsComments = "isComments",
}

export const notificationMessage = {
  [Field.AllowAccountActivation]: NOTIFICATIONS.FRIENDS_CODE_SHARING_UPDATED,
  [Field.AllowWaitingList]: NOTIFICATIONS.IS_WAITING_LIST_UPDATED,
  [Field.RequireInvitation]: NOTIFICATIONS.ACCESS_APP_INVITATION_UPDATED,
  [Field.AllowCoupons]: NOTIFICATIONS.IS_COUPONS_UPDATED,
  [Field.AllowTopUpCoupons]: NOTIFICATIONS.IS_COUPONS_UPDATED,
  [Field.AllowMenuItemsGrid]: NOTIFICATIONS.IS_MENU_ITEMS_GRID_UPDATED,
  [Field.IsApplePayAllowed]: NOTIFICATIONS.IS_APPLEPAY_UPDATED,
  [Field.IsAppAccessible]: NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
  [Field.IsTabbyAllowed]: NOTIFICATIONS.IS_TABBY_UPDATED,
  [Field.IsTalyAllowed]: NOTIFICATIONS.IS_TALY_UPDATED,
  [Field.IsTamaraAllowed]: NOTIFICATIONS.IS_TAMARA_UPDATED,
  [Field.IsExternalFiatCentsPriceVisible]: NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
  [Field.IsComments]: NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
  [Field.IsFoodLibraryEnabled]: NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
};

export type StateValues = {
  [Field.AllowAccountActivation]: undefined | boolean;
  [Field.AllowWaitingList]: undefined | boolean;
  [Field.RequireInvitation]: undefined | boolean;
  [Field.AllowCoupons]: undefined | boolean;
  [Field.AllowTopUpCoupons]: undefined | boolean;
  [Field.AllowMenuItemsGrid]: undefined | boolean;
  [Field.IsApplePayAllowed]: undefined | boolean;
  [Field.IsAppAccessible]: undefined | boolean;
  [Field.IsTabbyAllowed]: undefined | boolean;
  [Field.IsTalyAllowed]: undefined | boolean;
  [Field.IsTamaraAllowed]: undefined | boolean;
  [Field.IsExternalFiatCentsPriceVisible]: undefined | boolean;
  [Field.IsComments]: undefined | boolean;
  [Field.IsFoodLibraryEnabled]: undefined | boolean;
};

export const initialState: StateValues = {
  [Field.AllowAccountActivation]: undefined,
  [Field.AllowWaitingList]: undefined,
  [Field.RequireInvitation]: undefined,
  [Field.AllowCoupons]: undefined,
  [Field.AllowTopUpCoupons]: undefined,
  [Field.AllowMenuItemsGrid]: undefined,
  [Field.IsApplePayAllowed]: undefined,
  [Field.IsAppAccessible]: undefined,
  [Field.IsTabbyAllowed]: undefined,
  [Field.IsTalyAllowed]: undefined,
  [Field.IsTamaraAllowed]: undefined,
  [Field.IsExternalFiatCentsPriceVisible]: undefined,
  [Field.IsComments]: undefined,
  [Field.IsFoodLibraryEnabled]: undefined,
};

export type LoadingStateValues = Record<keyof StateValues, boolean>;

export const initialLoadingState: LoadingStateValues = {
  [Field.AllowAccountActivation]: false,
  [Field.AllowWaitingList]: false,
  [Field.RequireInvitation]: false,
  [Field.AllowCoupons]: false,
  [Field.AllowTopUpCoupons]: false,
  [Field.AllowMenuItemsGrid]: false,
  [Field.IsApplePayAllowed]: false,
  [Field.IsAppAccessible]: false,
  [Field.IsTabbyAllowed]: false,
  [Field.IsTalyAllowed]: false,
  [Field.IsTamaraAllowed]: false,
  [Field.IsExternalFiatCentsPriceVisible]: false,
  [Field.IsComments]: false,
  [Field.IsFoodLibraryEnabled]: false,
};

export type ConfirmModalType = {
  key: Field;
  value: boolean | number;
};
