// Lib
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useCreateAggregatorOrderMutation,
  useLazyValidateAggregatorOrderIdQuery,
} from "rtkQuery/query/ordersAPI";
// Hooks
import { useDebounce, useNotification, useViewport } from "hooks";
// Types
import { CompleteProductData, CustomerData, MenuStatuses } from "./types";
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
import { generateUniqueId } from "helpers/dataHelpers";
import { calculateSummary, handleMutateOrderItems } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DiningIcon, RightBurgerMenuIcon } from "icons";
// Components
import { ArrowBackLink, DropDown } from "components";
import { ConfirmDialog } from "components/Modals";
import { Informations, OrderDetails, OrderSummary, Store } from "./components";
// Styled
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

const emptyCustomerData: CustomerData = {
  orderId: "",
  hashTag: "",
  firstName: "",
  phone: "",
  phoneCode: "",
  service: "",
};

export const AggregatorOrderCreate: FC = () => {
  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const [createOrder] = useCreateAggregatorOrderMutation();
  const [validate] = useLazyValidateAggregatorOrderIdQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [orderIdError, setOrderIdError] = useState<string>("");

  const [isOrderCreateConfirm, setIsOrderCreateConfirm] = useState(false);

  const [locationId, setLocationId] = useState<string>("");
  const [menuId, setMenuId] = useState<string>("");
  const [menuStatuses, setMenuStatuses] = useState<MenuStatuses>({
    isClosed: false,
    isBusy: false,
    isMenuCashPaymentAvailable: false,
  });

  const [customerData, setCustomerData] =
    useState<CustomerData>(emptyCustomerData);

  const [orderItems, setOrderItems] = useState<CompleteProductData[]>([]);

  const [isCutlery, setIsCutlery] = useState(false);

  const debouncedOrderId = useDebounce(customerData.orderId);

  useEffect(() => {
    if (!debouncedOrderId) {
      setOrderIdError("");

      return;
    }

    validateId(debouncedOrderId);
  }, [debouncedOrderId]);

  const validateId = async (id: string) => {
    try {
      await validate({ id }).unwrap();

      setOrderIdError("");
    } catch (error) {
      if (error?.data?.message) {
        setOrderIdError(error.data.message);
        return;
      }

      errorHandler({ error, openNotification });
    }
  };

  const onLocationChange = useCallback((id: string) => {
    setLocationId(id);
    setOrderItems([]);
  }, []);

  const handleAddOrderItem = useCallback((data: CompleteProductData) => {
    if (!data?.uniqId) {
      setOrderItems(prev =>
        prev.concat({ ...data, uniqId: generateUniqueId() }),
      );

      openNotification({ message: `${data.product.name} successfully added` });
      return;
    }

    setOrderItems(prev => handleMutateOrderItems(prev, data));

    openNotification({ message: `${data.product.name} successfully updated` });
  }, []);

  const handleRemoveOrderItem = useCallback((id: string) => {
    setOrderItems(prev => prev.filter(i => i.uniqId !== id));
  }, []);

  const summary = useMemo(
    () =>
      calculateSummary(orderItems, {
        fiatCentsPrice: 0,
        totalFmcCentsPrice: 0,
      }),
    [orderItems],
  );

  const handlePlaceOrder = useCallback(() => {
    setIsOrderCreateConfirm(true);
  }, []);

  const handleCreateOrder = async () => {
    try {
      setIsLoading(true);

      const items = orderItems.map(i => ({
        productId: i.product.id,
        quantity: i.quantity,
        fmcCentsPrice: i.product.fmcCentsPrice,
        remark: i.remark,
        subItems: [
          ...i.addonsModifiers.addon,
          ...i.addonsModifiers.modifier,
        ].map(a => ({
          productId: a.id,
          quantity: a.quantity,
          fmcCentsPrice: a.fmcCentsPrice,
        })),
      }));

      const { orderId, hashTag, firstName, phone, phoneCode, service } =
        customerData;

      const order = await createOrder({
        menuId,
        locationId,
        items,
        service: service.toLowerCase(),
        totalFmcCentsPrice: summary.subTotal.coins,
        totalFiatCentsPrice: summary.subTotal.fiat,
        cutlery: isCutlery,
        phoneNumber: `+${phoneCode}${phone}`,
        firstName,
        orderId,
        hashTag,
      }).unwrap();

      openNotification({ message: "Order successfully placed" });

      setIsOrderCreateConfirm(false);

      navigate(`${ADMIN_ROUTES.ORDERS.path}/aggregator/${order.id}`);
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const isCustomerDataFilled =
    !!customerData.firstName &&
    !!customerData.phoneCode &&
    !!customerData.phone;

  const isOrderCreatingAvailable =
    !!orderItems?.length && isCustomerDataFilled && !orderIdError;

  const DropDownMenuItems = [
    {
      key: "1",
      label: "Discard",
      onClick: () => setCustomerData(emptyCustomerData),
    },
    {
      key: "2",
      label: "Place Order",
      disabled: !isOrderCreatingAvailable,
      onClick: handlePlaceOrder,
    },
  ];

  return (
    <>
      <PageHeadingContainer $margin="0 0 24px 0">
        <PageTitleContainer $column>
          <ArrowBackLink
            title="Back to Orders"
            path={ADMIN_ROUTES.ORDERS.path}
          />

          <FlexContainer
            $gap={12}
            $align="center"
            $justify="center"
            $margin="20px 0 0 0"
          >
            <Typography.H1>Add New Aggregator Order</Typography.H1>
          </FlexContainer>
        </PageTitleContainer>

        {isDesktop ? (
          <FlexContainer $gap={8}>
            <Button.Heading onClick={() => setCustomerData(emptyCustomerData)}>
              Discard
            </Button.Heading>

            <Button.Heading
              type="primary"
              disabled={!isOrderCreatingAvailable}
              onClick={handlePlaceOrder}
            >
              Place Order
            </Button.Heading>
          </FlexContainer>
        ) : (
          <DropDown items={DropDownMenuItems} trigger={["click"]}>
            <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
          </DropDown>
        )}
      </PageHeadingContainer>

      <FlexContainer $column $gap={16}>
        <Informations
          orderIdError={orderIdError}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />

        <Store
          isCustomerSelected={!!customerData}
          locationId={locationId}
          onLocationChange={onLocationChange}
        />

        <OrderDetails
          isBlocked={!locationId}
          menuStatuses={menuStatuses}
          locationId={locationId}
          orderItems={orderItems}
          isCutlery={isCutlery}
          setMenuStatuses={setMenuStatuses}
          setMenuId={setMenuId}
          handleAddOrderItem={handleAddOrderItem}
          handleRemoveOrderItem={handleRemoveOrderItem}
          setIsCutlery={setIsCutlery}
        />

        <OrderSummary
          isBlocked={!locationId}
          menuStatuses={menuStatuses}
          summary={summary}
        />
      </FlexContainer>

      <ConfirmDialog
        isLoading={isLoading}
        open={isOrderCreateConfirm}
        Icon={DiningIcon}
        message="Place Order"
        description="Are you sure to continue this action?"
        onCancel={() => setIsOrderCreateConfirm(false)}
        firstCTAButton={{
          title: "Confirm",
          disabled: false,
          type: "primary",
          loading: isLoading,
          onClick: handleCreateOrder,
        }}
      />
    </>
  );
};
