// Lib
import { FC, ReactNode } from "react";
// Icons
import { DiningIcon } from "icons";
// Components
import { Badge } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { ImagePlaceholder, MenuItem, Image } from "./styled";

import { theme } from "theme";

interface ProductItemProps {
  id?: string | number;
  name?: string;
  imageURL?: string;
  isScheduled?: boolean;
  isSnoozed?: boolean;
  content?: ReactNode;
  children?: ReactNode;
}

export const ProductItem: FC<ProductItemProps> = ({
  id,
  name,
  imageURL,
  isScheduled,
  isSnoozed,
  content,
  children,
}) => {
  return (
    <MenuItem key={id} $fullwidth $padding="8px" $gap={8}>
      <FlexContainer $fullwidth $gap={8}>
        <div>
          {imageURL ? (
            <Image src={imageURL} />
          ) : (
            <ImagePlaceholder>
              <DiningIcon height={"64px"} width={"64px"} />
            </ImagePlaceholder>
          )}
        </div>

        <FlexContainer $fullwidth $column $gap={8} $justify="center">
          <FlexContainer $gap={8} $wrap>
            <Typography.Title>{name}</Typography.Title>

            {isScheduled && isSnoozed && (
              <Badge
                title="Out of stock"
                textColor={theme.color.white}
                bgColor="rgba(250, 80, 69, 1)"
              />
            )}
          </FlexContainer>
        </FlexContainer>

        {children && <FlexContainer $align="center">{children}</FlexContainer>}
      </FlexContainer>

      {content}
    </MenuItem>
  );
};
