import dayjs from "dayjs";
import {
  Coupon,
  CouponAllowedUser,
  CouponKindType,
  CouponPaymentLabel,
  CouponType,
  CouponTypeLabel,
  CreateCouponRequestData,
  TopUpDiscountTypeLabel,
} from "types/coupons";

import { CustomerProfileResponseDto } from "types/customers";
import { OrderCouponForm, TopUpCouponForm, UsersType, UseType } from "./types";

import { DiscountType, DiscountTypeLabel } from "types/coupons";
import { AllPaymentTypes, OrderPaymentType } from "types/orders";
import { fixedDigitsValue } from "helpers/dataHelpers";
import { FlexContainer } from "styled/Box";
import { CurrencyIcon } from "icons";

export const couponTypeOptions = [
  {
    value: CouponType.GiftVoucher,
    label: CouponTypeLabel[CouponType.GiftVoucher],
  },
  {
    value: CouponType.DiscountCode,
    label: CouponTypeLabel[CouponType.DiscountCode],
  },
];

export const discountTypeOptions = [
  {
    value: DiscountType.Percentage,
    label: DiscountTypeLabel[DiscountType.Percentage],
  },
  {
    value: DiscountType.FiatCents,
    label: DiscountTypeLabel[DiscountType.FiatCents],
  },
  {
    value: DiscountType.FmcCents,
    label: DiscountTypeLabel[DiscountType.FmcCents],
  },
];

export const topUpDiscountTypeOptions = [
  {
    value: DiscountType.Percentage,
    label: TopUpDiscountTypeLabel[DiscountType.Percentage],
  },
  {
    value: DiscountType.PercentageDiscount,
    label: TopUpDiscountTypeLabel[DiscountType.PercentageDiscount],
  },
  {
    value: DiscountType.FmcCents,
    label: TopUpDiscountTypeLabel[DiscountType.FmcCents],
  },
];

export const paymentTypeOptions = [
  {
    value: AllPaymentTypes.All,
    label: CouponPaymentLabel[AllPaymentTypes.All],
  },
  {
    value: OrderPaymentType.FiatCents,
    label: CouponPaymentLabel[OrderPaymentType.FiatCents],
  },
  {
    value: OrderPaymentType.FmcCents,
    label: CouponPaymentLabel[OrderPaymentType.FmcCents],
  },
];

export const getDiscountLabel = (discountType: DiscountType) => {
  switch (discountType) {
    case DiscountType.Percentage:
      return "Discount (%)";
    case DiscountType.FiatCents:
      return "Discount (KWD)";
    case DiscountType.FmcCents:
      return "Discount (FM Coins)";

    default:
      return "Discount";
  }
};

export const getBonusLabel = (discountType: DiscountType) => {
  switch (discountType) {
    case DiscountType.Percentage:
      return "Bonus Coins (%)";
    case DiscountType.PercentageDiscount:
      return "Discount (%)";
    case DiscountType.FiatCents:
      return "Bonus (KWD)";
    case DiscountType.FmcCents:
      return "Bonus Coins (FM Coins)";

    default:
      return "Bonus Coins";
  }
};

export const transformToFormData = (data: Coupon): OrderCouponForm => {
  return {
    name: data.couponCode,
    discountOff: data?.discountOff,
    discountType: data?.discountType,
    startDate: dayjs(data?.startDate) || null,
    expiryDate: dayjs(data?.expiryDate) || null,
    numberOfUses: data?.numberOfUses,
    isActive: data?.isActive,
    orderPaymentType: data?.orderPaymentType,
    minimumOrderFiatCentsPrice: data?.minimumOrderFiatCentsPrice,
    minimumOrderFmcCentsPrice: data?.minimumOrderFmcCentsPrice,
    allowMultipleUsage: data?.allowMultipleUsage,
    couponType: data?.couponType,
    numberOfCouponsToGenerate: 1,
  };
};

export const transformToTopUpFormData = (data: Coupon): TopUpCouponForm => {
  const isFmcCentsType = data?.discountType === DiscountType.FmcCents;

  const discountOff = isFmcCentsType
    ? data?.discountFiatCents
    : data?.discountOff;
  return {
    name: data.couponCode,
    discountOff,
    discountType: data?.discountType,
    startDate: dayjs(data?.startDate) || null,
    expiryDate: dayjs(data?.expiryDate) || null,
    numberOfUses: data?.numberOfUses,
    numberOfUniqueUses: data?.numberOfUniqueUses,
    isActive: data?.isActive,
    orderPaymentType: data?.orderPaymentType,
    minimumOrderFiatCentsPrice: data?.minimumOrderFiatCentsPrice,
    minimumOrderFmcCentsPrice: data?.minimumOrderFmcCentsPrice,
    allowMultipleUsage: data?.allowMultipleUsage,
    couponType: data?.couponType,
    numberOfCouponsToGenerate: 1,
  };
};

export const transformCustomerToCouponUserData = (
  data: CustomerProfileResponseDto,
): CouponAllowedUser => {
  const { id, fullName, email, phoneNumber } = data || {};
  return { id, fullName, email, phoneNumber };
};

export const transformOrderCouponsFormToRequestData = (
  data: OrderCouponForm,
  usersType: UsersType,
  useType: UseType,
  allowedUsers: CouponAllowedUser[],
): CreateCouponRequestData => {
  return {
    ...data,
    startDate: data?.startDate.toISOString(),
    expiryDate: data?.expiryDate.toISOString(),
    type: CouponKindType.Order,
    allowedUsers:
      usersType === UsersType.List ? allowedUsers.map(el => el.id) : [],
    numberOfUses: useType === UseType.OneTime ? 1 : data.numberOfUses,
  };
};

export const transformTopUpCouponsFormToRequestData = (
  data: TopUpCouponForm,
  usersType: UsersType,
  useType: UseType,
  allowedUsers: CouponAllowedUser[],
  discountType: DiscountType,
  exchangeRate: number,
  calculatedCoinsAmount: number,
): CreateCouponRequestData => {
  const isFmcCentsTypeSelected = discountType === DiscountType.FmcCents;

  const discountOff = isFmcCentsTypeSelected
    ? calculatedCoinsAmount
    : data.discountOff;

  const discountFiatCents = isFmcCentsTypeSelected
    ? data.discountOff
    : undefined;

  return {
    ...data,
    discountOff,
    discountFiatCents,
    startDate: data?.startDate.toISOString(),
    expiryDate: data?.expiryDate.toISOString(),
    type: CouponKindType.TopUp,
    allowedUsers:
      usersType === UsersType.List ? allowedUsers.map(el => el.id) : [],
    numberOfUses: useType === UseType.Multiple ? data.numberOfUses : 1,
    exchangeRate: isFmcCentsTypeSelected ? exchangeRate : undefined,
    numberOfUniqueUses: isFmcCentsTypeSelected
      ? data.numberOfUniqueUses
      : undefined,
  };
};

export const getCouponDiscountLabel = (value: number, coupon: Coupon) => {
  if (
    coupon?.discountType === DiscountType.Percentage ||
    coupon.couponType === CouponType.DiscountCodeFiat
  ) {
    return `${value}%`;
  }

  if (coupon?.discountType === DiscountType.FiatCents) {
    return `${fixedDigitsValue(value, 3)} KWD`;
  }

  if (coupon?.discountType === DiscountType.FmcCents) {
    return (
      <FlexContainer $align="center" $justify="flex-end" $gap={8}>
        {fixedDigitsValue(value, 3)} <CurrencyIcon />
      </FlexContainer>
    );
  }

  return value;
};
