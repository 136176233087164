import { dateTransform } from "helpers/dataHelpers";
import { FilterField, FilterOption } from "types/tableFilters";
import { TextFieldsIcon } from "icons";
import {
  FoodLibraryProductFilterNames,
  FoodLibraryProductFilterTypes,
} from "types/foodLibrary";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Calories",
    dataIndex: "calories",
    key: "calories",
  },
  {
    title: "Carbs",
    dataIndex: "carbs",
    key: "carbs",
  },
  {
    title: "Fat",
    dataIndex: "fat",
    key: "fat",
  },
  {
    title: "Protein",
    dataIndex: "protein",
    key: "protein",
  },
  {
    title: "Serving size",
    dataIndex: "servingSize",
    key: "servingSize",
  },
  {
    title: "Servings number",
    dataIndex: "servingsNumber",
    key: "couponUsageCount",
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
];

export const filtersList: FilterOption[] = [
  {
    key: FoodLibraryProductFilterTypes.name,
    icon: <TextFieldsIcon />,
    label: FoodLibraryProductFilterNames[FoodLibraryProductFilterTypes.name],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: FoodLibraryProductFilterTypes.code,
    icon: <TextFieldsIcon />,
    label: FoodLibraryProductFilterNames[FoodLibraryProductFilterTypes.code],
    field: FilterField.INPUT_SELECT,
  },
];
