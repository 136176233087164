import { FoodLibraryProductForm } from "./types";

export const defaultValues: FoodLibraryProductForm = {
  name: "",
  calories: 0,
  carbs: 0,
  fat: 0,
  protein: 0,
  servingSize: "",
  servingsNumber: 0,
  code: "",
};
