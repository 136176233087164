import { generateUniqueId } from "./../helpers/dataHelpers";
import {
  CENTS_IN_COIN_RATE,
  KWD_RATE,
  Permission,
  initialPermissions,
} from "consts";
import { getFullName, removeHashContent } from "helpers/dataHelpers";

import { GetUsersResponseDto, User } from "types/users";
import { UpdateLocationRequestDto } from "types/locations";
import { MenuProductResponseDto, ProductResponseDto } from "types/recommended";
import {
  AuthSettingsListOptionResponseDto,
  AuthSettingsResponseDto,
} from "types/authSettings";
import { SetSettingsRequestDto, SettingsResponseDto } from "types/settings";
import { GetReferralsResponseDto, ReferralsSettingsDto } from "types/referrals";
import {
  PaymentsSettingsResponseDto,
  SetPaymentsSettingsRequestDto,
} from "types/paymentsSettings";
import {
  CreateBulkCustomerTransactionRequestDto,
  CreateCustomerTransactionRequestDto,
  GetTransactionsResponseDto,
} from "types/transactions";
import {
  CreateOrderRequestDto,
  CreateAggregatorOrderRequestDto,
  GetOrdersResponseDto,
  GetScheduledOrdersResponseDto,
  GetAggregatorOrdersResponseDto,
  OrderResponseDto,
  OrderStatus,
  ScheduledOrderResponseDto,
  AggregatorOrderResponseDto,
} from "types/orders";
import {
  CustomerBalanceResponseDto,
  GetCustomerMenuResponseDto,
  GetCustomersMappedResponseDto,
  GetCustomersResponseDto,
} from "types/customers";
import {
  CreateOrUpdatePackageRequestDto,
  CreateOrUpdatePackageRulesRequestDto,
  PackageResponseDto,
  PackageRulesResponseDto,
  TrialPackageResponseDto,
} from "types/packages";
import {
  Coupon,
  CouponType,
  CreateCouponRequestDto,
  DiscountType,
  GetCouponsResponseDto,
} from "types/coupons";
import {
  CoreSettingsResponseDto,
  RewardsSettingsResponseDto,
  SetCoreSettingsRequestDto,
} from "types/coreSettings";

export const permissionsMapper = (
  user: User,
): Record<keyof typeof initialPermissions, boolean> => {
  const userPermissions = user.role.permissions;
  const userRole = user.role.name;

  if (!userPermissions?.length) {
    return initialPermissions;
  }

  const userCan: typeof initialPermissions = userPermissions
    .map(({ name }) => name)
    .reduce(
      (
        acc: Record<keyof typeof initialPermissions, boolean>,
        el: string,
      ): Record<keyof typeof initialPermissions, boolean> => {
        switch (el) {
          /* Dashboard */
          case Permission.StatisticsGet:
            acc.canStatisticsGet = true;
            break;
          /* Orders */
          case Permission.OrdersGet:
            acc.canOrdersGet = true;
            break;
          case Permission.OrdersCreate:
            acc.canOrdersCreate = true;
            break;

          /* Users section */
          case Permission.UsersGet:
            acc.canUsersGet = true;
            break;
          case Permission.UsersCreate:
            acc.canUsersCreate = true;
            break;
          case Permission.UserAgentOnlyCreate:
            acc.canUserAgentOnlyCreate = true;
            break;
          case Permission.UsersUpdate:
            acc.canUsersUpdate = true;
            break;
          case Permission.UsersDelete:
            acc.canUsersDelete = true;
            break;

          /* Roles section */
          case Permission.PermissionsGet:
            acc.canPermissionsGet = true;
            break;
          case Permission.RolesGet:
            acc.canRolesGet = true;
            break;
          case Permission.RolesCreate:
            acc.canRolesCreate = true;
            break;
          case Permission.RolesUpdate:
            acc.canRolesUpdate = true;
            break;
          case Permission.RolesDelete:
            acc.canRolesDelete = true;
            break;

          case Permission.SuperAdminManagement:
            acc.isSuperAdminManagement = true;
            break;

          /* Transactions section */
          case Permission.TransactionsCreate:
            acc.canTransactionsCreate = true;
            break;
          case Permission.TransactionsGet:
            acc.canTransactionsGet = true;
            break;

          /* Customers section */
          case Permission.CustomerBase:
            acc.canCustomerBase = true;
            break;
          case Permission.CustomersVIPStatus:
            acc.canCustomerVIPStatusUpdate = true;
            break;
          case Permission.CustomersGet:
            acc.canCustomersGet = true;
            break;
          case Permission.CustomersActivate:
            acc.canCustomersActivate = true;
            break;
          case Permission.BalanceGet:
            acc.canBalanceGet = true;
            break;

          /* Delivery areas section */
          case Permission.LocationsUpdate:
            acc.canLocationsUpdate = true;
            break;
          case Permission.LocationsGet:
            acc.canLocationsGet = true;
            break;
          case Permission.LocationsMenuGet:
            acc.canLocationsMenuGet = true;
            break;

          /* Coin Packages section */

          case Permission.PackagesGet:
            acc.canPackagesGet = true;
            break;
          case Permission.PackagesCreate:
            acc.canPackagesCreate = true;
            break;
          case Permission.PackagesUpdate:
            acc.canPackagesUpdate = true;
            break;

          /* Coupons section */

          case Permission.CouponsGet:
            acc.canCouponsGet = true;
            break;
          case Permission.CouponsCreate:
            acc.canCouponsCreate = true;
            break;
          case Permission.CouponsUpdate:
            acc.canCouponsUpdate = true;
            break;
          case Permission.CouponsDelete:
            acc.canCouponsDelete = true;
            break;

          /* Settings section */
          case Permission.WaitingListUpdate:
            acc.canUpdateWaitingListSettings = true;
            break;
          case Permission.ExchangeRateUpdate:
            acc.canExchangeRateUpdate = true;
            break;
          case Permission.BalanceExpirationUpdate:
            acc.canBalanceExpirationUpdate = true;
            break;
          case Permission.SettingsGet:
            acc.canSettingsGet = true;
            break;
          case Permission.StockManagementGet:
            acc.canStockManagementSettingsGet = true;
            break;
          case Permission.SettingsUpdate:
            acc.canSettingsUpdate = true;
            break;
          case Permission.SettingsPurposeGet:
            acc.canPurposeGet = true;
            break;
          case Permission.SettingsPurposeCreate:
            acc.canPurposeCreate = true;
            break;
          case Permission.SettingsPurposeUpdate:
            acc.canPurposeUpdate = true;
            break;
          case Permission.SettingsPurposeDelete:
            acc.canPurposeDelete = true;
            break;
          case Permission.BalanceExpirationAgentLimitUpdate:
            acc.isBalanceExpirationAgentLimitUpdate = true;
            break;
          case Permission.BalanceExpirationManagerLimitUpdate:
            acc.isBalanceExpirationManagerLimitUpdate = true;
            break;
          case Permission.RefundAddAgentLimitUpdate:
            acc.isRefundAddAgentLimitUpdate = true;
            break;
          case Permission.RefundAddManagerLimitUpdate:
            acc.isAddManagerLimitUpdate = true;
            break;
          case Permission.SettingsTimeSlotsGet:
            acc.canTimeSlotsGet = true;
            break;
          case Permission.SettingsTimeSlotsCreate:
            acc.canTimeSlotsCreate = true;
            break;
          case Permission.SettingsTimeSlotsUpdate:
            acc.canTimeSlotsUpdate = true;
            break;
          case Permission.SettingsTimeSlotsDelete:
            acc.canTimeSlotsDelete = true;
            break;
          case Permission.SettingsPaymentsGet:
            acc.canPaymentSettingsGet = true;
            break;
          case Permission.SettingsPaymentsUpdate:
            acc.canPaymentSettingsUpdate = true;
            break;
          case Permission.SettingsReferralsGet:
            acc.canReferralsSettingsGet = true;
            break;
          case Permission.SettingsReferralsUpdate:
            acc.canReferralsSettingsUpdate = true;
            break;
          case Permission.SettingsScheduleOrderGet:
            acc.canScheduleOrderSettingsGet = true;
            break;
          case Permission.SettingsScheduleOrderUpdate:
            acc.canScheduleOrderSettingsUpdate = true;
            break;
          case Permission.SettingsCouponsGet:
            acc.canCouponsSettingsGet = true;
            break;
          case Permission.SettingsCouponsUpdate:
            acc.canCouponsSettingsUpdate = true;
            break;
          case Permission.SettingsMenuGridGet:
            acc.canMenuGridSettingsGet = true;
            break;
          case Permission.SettingsMenuGridUpdate:
            acc.canMenuGridSettingsUpdate = true;
            break;

          /* other */
          case Permission.EmployeesGet:
            acc.canEmployeesGet = true;
            break;
          case Permission.EmployeesCreate:
            acc.canEmployeesCreate = true;
            break;
          case Permission.InvitationsCreate:
            acc.canInvitationsCreate = true;
            break;
          case Permission.InvitationsGet:
            acc.canInvitationsGet = true;
            break;

          case Permission.DeliveriesAllOptions:
            acc.canDeliveriesAllOptions = true;
            break;

          case Permission.LocationProvidersGet:
            acc.canGetLocationProviders = true;
            break;
        }

        return acc;
      },
      { ...initialPermissions },
    );

  userCan.isUserAgent = userRole.toLowerCase() === "agent";
  userCan.isUserManager = userRole.toLowerCase() === "manager";

  return userCan;
};

const devideByCentsInCoinRate = (value: number) => {
  if (!value) {
    return 0;
  }
  return (value * 1000) / CENTS_IN_COIN_RATE / 1000;
};

const multiplyByCentsInCoinRate = (value: number) => {
  if (!value) {
    return 0;
  }
  return (value * 1000 * CENTS_IN_COIN_RATE) / 1000;
};

export const ordersMapper = (response: GetOrdersResponseDto) => {
  const { items, ...restResponse } = response;

  return {
    ...restResponse,
    items: items.map(item => ({
      ...item,
      totalFmcCentsPrice: devideByCentsInCoinRate(item.totalFmcCentsPrice),
      totalFiatCentsPrice: devideByCentsInCoinRate(item.totalFiatCentsPrice),
      fullName: getFullName({
        firstName: item?.firstName,
        lastName: item.lastName,
      }),
    })),
  };
};

export const aggregatorOrdersMapper = (
  response: GetAggregatorOrdersResponseDto,
) => {
  const { items, ...restResponse } = response;

  return {
    ...restResponse,
    items: items.map(item => ({
      ...item,
      totalFmcCentsPrice: devideByCentsInCoinRate(item.totalFmcCentsPrice),
      totalFiatCentsPrice: devideByCentsInCoinRate(item.totalFiatCentsPrice),
    })),
  };
};

export const scheduledOrdersMapper = (
  response: GetScheduledOrdersResponseDto,
) => {
  const { items, ...restResponse } = response;

  return {
    ...restResponse,
    items: items.map(item => ({
      ...item,
      totalFmcCentsPrice: devideByCentsInCoinRate(item.totalFmcCentsPrice),
      totalFiatCentsPrice: devideByCentsInCoinRate(item.totalFiatCentsPrice),
      fullName: getFullName({
        firstName: item?.firstName,
        lastName: item.lastName,
      }),
      isOutOfStock: !!item.items.find(orderItem =>
        Boolean(orderItem.isSnoozed),
      ),
      DeliveredAt:
        item.orderStatus === OrderStatus.DELIVERED ? item.updatedAt : null,
    })),
  };
};

export const customersMapper = (
  response: GetCustomersResponseDto,
): GetCustomersMappedResponseDto => {
  const { items, ...restResponse } = response;

  return {
    ...restResponse,
    items: items.map(profile => {
      const { firstName, lastName, balanceData } = profile;

      const fullName = getFullName({ firstName, lastName });

      return {
        ...profile,
        fullName,
        balanceData: {
          ...balanceData,
          fmcCentsAmount: devideByCentsInCoinRate(balanceData?.fmcCentsAmount),
        },
      };
    }),
  };
};

export const customerMenuMapper = (
  response: GetCustomerMenuResponseDto,
): GetCustomerMenuResponseDto => {
  const { categories, ...restResponse } = response;

  return {
    ...restResponse,
    categories: categories.map(category => {
      const { products } = category;

      return {
        ...category,

        products: products.map(product => ({
          ...product,
          fiatCentsPrice: devideByCentsInCoinRate(product.fiatCentsPrice),
          fmcCentsPrice: devideByCentsInCoinRate(product.fmcCentsPrice),
          addons: product.addons.map(addon => ({
            ...addon,
            type: "addon",
            name: removeHashContent(addon.name),
            fiatCentsPrice: devideByCentsInCoinRate(addon.fiatCentsPrice),
            fmcCentsPrice: devideByCentsInCoinRate(addon.fmcCentsPrice),
          })),
          modifiers: product.modifiers.map(modifier => ({
            ...modifier,
            name: removeHashContent(modifier.name),
            fiatCentsPrice: devideByCentsInCoinRate(modifier.fiatCentsPrice),
            fmcCentsPrice: devideByCentsInCoinRate(modifier.fmcCentsPrice),
          })),
        })),
      };
    }),
  };
};

export const balanceMapper = (balance: CustomerBalanceResponseDto) => {
  return {
    ...balance,
    fmcCentsAmount: devideByCentsInCoinRate(balance.fmcCentsAmount),
  };
};

export const transactionsMapper = (response: GetTransactionsResponseDto) => {
  const { items, ...restResponse } = response;

  return {
    ...restResponse,
    items: items.map(item => ({
      ...item,
      fmcCentsAmount: devideByCentsInCoinRate(item.fmcCentsAmount),
      customerBalance: devideByCentsInCoinRate(item.customerBalance),
      payment: {
        ...item.payment,
        amount: item.payment?.amount / KWD_RATE || null,
      },
      fullName: getFullName({
        firstName: item.firstName,
        lastName: item.lastName,
      }),
    })),
  };
};

export const topUpMapper = ({
  fmcCentsAmount,
  ...restData
}: CreateCustomerTransactionRequestDto) => ({
  ...restData,
  fmcCentsAmount: multiplyByCentsInCoinRate(fmcCentsAmount),
});

export const bulkTopUpMapper = (
  payload: CreateBulkCustomerTransactionRequestDto,
): CreateBulkCustomerTransactionRequestDto => {
  return {
    transactions: payload.transactions.map(transaction =>
      topUpMapper(transaction),
    ),
  };
};

export const orderMapper = (order: OrderResponseDto): OrderResponseDto => {
  const {
    totalFiatCentsPrice,
    totalFmcCentsPrice,
    items,
    firstName,
    lastName,
    delivery,
    ...restOrder
  } = order;

  return {
    ...restOrder,
    firstName,
    lastName,
    fullName: getFullName({ firstName, lastName }),
    totalFiatCentsPrice: devideByCentsInCoinRate(totalFiatCentsPrice),
    totalFmcCentsPrice: devideByCentsInCoinRate(totalFmcCentsPrice),
    items: items.map(item => ({
      ...item,
      fmcCentsPrice: devideByCentsInCoinRate(item.fmcCentsPrice),
      fiatCentsPrice: devideByCentsInCoinRate(item.fiatCentsPrice),
    })),
    delivery: {
      ...delivery,
      deliveryFeeFiatCents: devideByCentsInCoinRate(
        delivery.deliveryFeeFiatCents,
      ),
      deliveryFeeFmcCents: devideByCentsInCoinRate(
        delivery.deliveryFeeFmcCents,
      ),
    },
  };
};
export const aggregatorOrderMapper = (
  order: AggregatorOrderResponseDto,
): AggregatorOrderResponseDto => {
  const {
    totalFiatCentsPrice,
    totalFmcCentsPrice,
    items,
    firstName,
    ...restOrder
  } = order;

  return {
    ...restOrder,
    firstName,
    totalFiatCentsPrice: devideByCentsInCoinRate(totalFiatCentsPrice),
    totalFmcCentsPrice: devideByCentsInCoinRate(totalFmcCentsPrice),
    items: items.map(item => ({
      ...item,
      fmcCentsPrice: devideByCentsInCoinRate(item.fmcCentsPrice),
      fiatCentsPrice: devideByCentsInCoinRate(item.fiatCentsPrice),
    })),
  };
};

export const scheduledOrderMapper = (
  order: ScheduledOrderResponseDto,
): ScheduledOrderResponseDto => {
  const {
    totalFiatCentsPrice,
    totalFmcCentsPrice,
    items,
    firstName,
    lastName,
    delivery,
    ...restOrder
  } = order;

  return {
    ...restOrder,
    firstName,
    lastName,
    fullName: getFullName({ firstName, lastName }),
    totalFiatCentsPrice: devideByCentsInCoinRate(totalFiatCentsPrice),
    totalFmcCentsPrice: devideByCentsInCoinRate(totalFmcCentsPrice),
    items: items.map(item => ({
      ...item,
      fmcCentsPrice: devideByCentsInCoinRate(item.fmcCentsPrice),
      fiatCentsPrice: devideByCentsInCoinRate(item.fiatCentsPrice),
    })),
    deliveredAt:
      order.orderStatus === OrderStatus.DELIVERED ? order.updatedAt : "",
    delivery: {
      ...delivery,
      deliveryFeeFiatCents: devideByCentsInCoinRate(
        delivery.deliveryFeeFiatCents,
      ),
      deliveryFeeFmcCents: devideByCentsInCoinRate(
        delivery.deliveryFeeFmcCents,
      ),
    },
  };
};

export const minValuesMapper = (data: SettingsResponseDto) => {
  const { minimumOrderValue, minimumTopUpValue, ...restData } = data;

  return {
    ...restData,
    minimumOrderValue: devideByCentsInCoinRate(minimumOrderValue),
    minimumTopUpValue: devideByCentsInCoinRate(minimumTopUpValue),
  };
};

export const minValuesPayloadMapper = (data: SetSettingsRequestDto) => {
  const { minimumOrderValue, minimumTopUpValue, ...restData } = data;

  return {
    ...restData,
    minimumOrderValue: multiplyByCentsInCoinRate(minimumOrderValue),
    minimumTopUpValue: multiplyByCentsInCoinRate(minimumTopUpValue),
  };
};

export const trialPackageMapper = (
  responseData: TrialPackageResponseDto,
): TrialPackageResponseDto => {
  return {
    ...responseData,
    price: devideByCentsInCoinRate(responseData.price),
  };
};

export const trialPackagePayloadMapper = (requestData: {
  active: boolean;
  price: number;
}): {
  active: boolean;
  price: number;
} => {
  return {
    ...requestData,
    price: multiplyByCentsInCoinRate(requestData.price),
  };
};

export const CoinPackageRulesMapper = (
  responseData: PackageRulesResponseDto[],
): PackageRulesResponseDto[] => {
  return responseData.map((data: PackageRulesResponseDto) => ({
    ...data,
    from: devideByCentsInCoinRate(data?.from),
    to: devideByCentsInCoinRate(data?.to),
  }));
};

export const CoinPackagesMapper = (
  responseData: PackageResponseDto[],
): PackageResponseDto[] => {
  return responseData.map((data: PackageResponseDto) => ({
    ...data,
    price: devideByCentsInCoinRate(data.price),
    coinsAmount: devideByCentsInCoinRate(data.coinsAmount),
  }));
};

export const CoinPackagesPayloadMapper = (
  requestData: CreateOrUpdatePackageRequestDto,
): CreateOrUpdatePackageRequestDto => {
  return {
    ...requestData,
    price: multiplyByCentsInCoinRate(requestData.price),
  };
};

export const PackageRulesPayloadMapper = (
  requestData: CreateOrUpdatePackageRulesRequestDto,
): CreateOrUpdatePackageRulesRequestDto => {
  return {
    ...requestData,
    from: multiplyByCentsInCoinRate(requestData.from),
    to: multiplyByCentsInCoinRate(requestData.to),
  };
};

export const usersMapper = (
  response: GetUsersResponseDto,
): GetUsersResponseDto => {
  const { items, ...restResponse } = response;

  return {
    ...restResponse,
    items: items.map(profile => {
      const { firstName, lastName } = profile;

      const fullName = getFullName({ firstName, lastName });

      return {
        ...profile,
        fullName,
      };
    }),
  };
};

export const locationsUpdatePayloadMapper = (
  payload: UpdateLocationRequestDto,
): UpdateLocationRequestDto => {
  const { operatingZones, ...restPayload } = payload;

  return {
    ...restPayload,
    operatingZones: operatingZones?.length === 0 ? null : operatingZones,
  };
};

export const recommendedProductsListMapper = (
  data: ProductResponseDto[],
): ProductResponseDto[] => {
  return data.map(item => ({
    ...item,
    fiatCentsPrice: devideByCentsInCoinRate(item.fiatCentsPrice),
    totalFmcCentsPrice: devideByCentsInCoinRate(item.totalFmcCentsPrice),
    recommendedItems: item.recommendedItems.map(i => ({
      ...i,
      fiatCentsPrice: devideByCentsInCoinRate(i.fiatCentsPrice),
      totalFmcCentsPrice: devideByCentsInCoinRate(i.totalFmcCentsPrice),
    })),
  }));
};

export const allMenuProductsListMapper = (
  data: MenuProductResponseDto[],
): MenuProductResponseDto[] => {
  return data.map(item => ({
    ...item,
    fiatCentsPrice: devideByCentsInCoinRate(item.fiatCentsPrice),
    totalFmcCentsPrice: devideByCentsInCoinRate(item.totalFmcCentsPrice),
  }));
};

export const purposeOptionsMapper = (
  data: AuthSettingsListOptionResponseDto[],
) =>
  data.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

export const authSettingsMapper = (
  responseData: AuthSettingsResponseDto,
): AuthSettingsResponseDto => {
  return {
    ...responseData,
    instantOrderCancellationTime: Math.round(
      responseData.instantOrderCancellationTime / 60,
    ),
    defaultDeliveryTime: Math.round(responseData.defaultDeliveryTime / 60),
    defaultPreparationTime: Math.round(
      responseData.defaultPreparationTime / 60,
    ),
  };
};
export const cashPaymentsMapper = (
  responseData: PaymentsSettingsResponseDto,
): PaymentsSettingsResponseDto => {
  return {
    ...responseData,
    deliveryFeeFiatCents: devideByCentsInCoinRate(
      responseData.deliveryFeeFiatCents,
    ),
    deliveryFeeFmcCents: devideByCentsInCoinRate(
      responseData.deliveryFeeFmcCents,
    ),
    minimumOrder: devideByCentsInCoinRate(responseData.minimumOrder),
  };
};

export const cashPaymentsPayloadMapper = (
  payload: SetPaymentsSettingsRequestDto,
): SetPaymentsSettingsRequestDto => {
  const {
    deliveryFeeFiatCents,
    deliveryFeeFmcCents,
    minimumOrder,
    ...restPayload
  } = payload;

  return {
    ...restPayload,
    deliveryFeeFiatCents: multiplyByCentsInCoinRate(deliveryFeeFiatCents),
    deliveryFeeFmcCents: multiplyByCentsInCoinRate(deliveryFeeFmcCents),
    minimumOrder: multiplyByCentsInCoinRate(minimumOrder),
  };
};

export const referralListMapper = (responseData: GetReferralsResponseDto) => {
  const { items, ...restData } = responseData;

  return {
    ...restData,
    items: items.map(item => ({
      ...item,
      bonusForInvitee: devideByCentsInCoinRate(item.bonusForInvitee),
      bonusForInviter: devideByCentsInCoinRate(item.bonusForInviter),
    })),
  };
};

export const referralSettingsMapper = (
  responseData: ReferralsSettingsDto,
): ReferralsSettingsDto => {
  return {
    ...responseData,
    bonusForInvitee: devideByCentsInCoinRate(responseData.bonusForInvitee),
    bonusForInviter: devideByCentsInCoinRate(responseData.bonusForInviter),
    referralBonusThreshold: devideByCentsInCoinRate(
      responseData.referralBonusThreshold,
    ),
  };
};

export const referralSettingsPayloadMapper = (
  payload: ReferralsSettingsDto,
): ReferralsSettingsDto => {
  return {
    ...payload,
    bonusForInvitee: multiplyByCentsInCoinRate(payload.bonusForInvitee),
    bonusForInviter: multiplyByCentsInCoinRate(payload.bonusForInviter),
    referralBonusThreshold: multiplyByCentsInCoinRate(
      payload.referralBonusThreshold,
    ),
  };
};

export const rewardsSettingsMapper = (
  payload: RewardsSettingsResponseDto,
): RewardsSettingsResponseDto => {
  return {
    ...payload,
    bonusCoins: devideByCentsInCoinRate(payload.bonusCoins),
  };
};

export const rewardsSettingsPayloadMapper = (
  payload: RewardsSettingsResponseDto,
): RewardsSettingsResponseDto => {
  return {
    ...payload,
    bonusCoins: multiplyByCentsInCoinRate(payload.bonusCoins),
  };
};

export const coreSettingsMapper = (
  responseData: CoreSettingsResponseDto,
): CoreSettingsResponseDto => {
  return {
    ...responseData,
    deliveryFeeSettings: {
      ...responseData.deliveryFeeSettings,
      feeRanges: responseData.deliveryFeeSettings?.feeRanges
        ?.map(({ minOrderValue, maxOrderValue, deliveryFee }) => ({
          minOrderValue: devideByCentsInCoinRate(minOrderValue),
          maxOrderValue: devideByCentsInCoinRate(maxOrderValue),
          deliveryFee: devideByCentsInCoinRate(deliveryFee),
          id: generateUniqueId(),
        }))
        .sort((a, b) => a.minOrderValue - b.minOrderValue),
    },
  };
};

export const coreSettingsDeliveryFeePayloadMapper = (
  payload: SetCoreSettingsRequestDto,
): SetCoreSettingsRequestDto => {
  return {
    deliveryFeeSettings: {
      ...payload.deliveryFeeSettings,
      feeRanges: payload.deliveryFeeSettings?.feeRanges?.map(
        ({ minOrderValue, maxOrderValue, deliveryFee }) => ({
          minOrderValue: multiplyByCentsInCoinRate(minOrderValue),
          maxOrderValue: multiplyByCentsInCoinRate(maxOrderValue),
          deliveryFee: multiplyByCentsInCoinRate(deliveryFee),
        }),
      ),
    },
  };
};

export const coreSettingsPayloadMapper = (
  payload: SetCoreSettingsRequestDto,
): SetCoreSettingsRequestDto => {
  const {
    instantOrderCancellationTime,
    defaultDeliveryTime,
    defaultPreparationTime,
  } = payload || {};
  return {
    ...payload,
    ...(instantOrderCancellationTime && {
      instantOrderCancellationTime: Math.round(
        instantOrderCancellationTime * 60,
      ),
    }),
    ...(defaultDeliveryTime && {
      defaultDeliveryTime: Math.round(defaultDeliveryTime * 60),
    }),
    ...(defaultPreparationTime && {
      defaultPreparationTime: Math.round(defaultPreparationTime * 60),
    }),
  };
};

export const couponMapper = (responseData: Coupon): Coupon => {
  const discountOff =
    [DiscountType.Percentage].includes(responseData?.discountType) ||
    responseData.couponType === CouponType.DiscountCodeFiat
      ? responseData?.discountOff
      : devideByCentsInCoinRate(responseData?.discountOff);

  const discountType =
    responseData.couponType === CouponType.DiscountCodeFiat
      ? DiscountType.PercentageDiscount
      : responseData.discountType;

  return {
    ...responseData,
    discountOff,
    discountType,
    minimumOrderFiatCentsPrice: devideByCentsInCoinRate(
      responseData?.minimumOrderFiatCentsPrice,
    ),
    minimumOrderFmcCentsPrice: devideByCentsInCoinRate(
      responseData?.minimumOrderFmcCentsPrice,
    ),
    allowedUsers:
      responseData?.allowedUsers?.map(user => ({
        ...user,
        fullName: getFullName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        }),
      })) || [],
    ...(responseData?.discountFiatCents && {
      discountFiatCents: devideByCentsInCoinRate(
        responseData?.discountFiatCents,
      ),
    }),
  };
};

export const couponsMapper = (
  responseData: GetCouponsResponseDto,
): GetCouponsResponseDto => {
  return {
    ...responseData,
    items: responseData?.items.map(item => couponMapper(item)),
  };
};

export const couponPayloadMapper = (
  payload: CreateCouponRequestDto,
): CreateCouponRequestDto => {
  const discountOff =
    [DiscountType.Percentage].includes(payload?.discountType) ||
    payload.couponType === CouponType.DiscountCodeFiat
      ? payload?.discountOff
      : multiplyByCentsInCoinRate(payload?.discountOff) || 0;

  const discountType =
    payload.couponType === CouponType.DiscountCodeFiat
      ? DiscountType.Percentage
      : payload.discountType;

  return {
    ...payload,
    minimumOrderFiatCentsPrice: multiplyByCentsInCoinRate(
      payload.minimumOrderFiatCentsPrice,
    ),
    minimumOrderFmcCentsPrice: multiplyByCentsInCoinRate(
      payload.minimumOrderFmcCentsPrice,
    ),
    discountOff,
    discountType,
    ...(payload?.discountFiatCents && {
      discountFiatCents: multiplyByCentsInCoinRate(payload?.discountFiatCents),
    }),
  };
};

export const createOrderPayloadMapper = (
  payload: CreateOrderRequestDto,
): CreateOrderRequestDto => {
  return {
    ...payload,
    totalFmcCentsPrice: multiplyByCentsInCoinRate(payload.totalFmcCentsPrice),
    totalFiatCentsPrice: multiplyByCentsInCoinRate(payload.totalFiatCentsPrice),
    deliveryFeeFmcCentsPrice: multiplyByCentsInCoinRate(
      payload.deliveryFeeFmcCentsPrice,
    ),
    deliveryFeeFiatCentsPrice: multiplyByCentsInCoinRate(
      payload.deliveryFeeFiatCentsPrice,
    ),
    items: payload.items.map(item => ({
      ...item,
      fmcCentsPrice: multiplyByCentsInCoinRate(item.fmcCentsPrice),
      subItems: item.subItems.map(s => ({
        ...s,
        fmcCentsPrice: multiplyByCentsInCoinRate(s.fmcCentsPrice),
      })),
    })),
  };
};

export const createAggregatorOrderPayloadMapper = (
  payload: CreateAggregatorOrderRequestDto,
): CreateAggregatorOrderRequestDto => {
  return {
    ...payload,
    totalFmcCentsPrice: multiplyByCentsInCoinRate(payload.totalFmcCentsPrice),
    totalFiatCentsPrice: multiplyByCentsInCoinRate(payload.totalFiatCentsPrice),
    items: payload.items.map(item => ({
      ...item,
      fmcCentsPrice: multiplyByCentsInCoinRate(item.fmcCentsPrice),
      subItems: item.subItems.map(s => ({
        ...s,
        fmcCentsPrice: multiplyByCentsInCoinRate(s.fmcCentsPrice),
      })),
    })),
  };
};
