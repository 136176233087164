import { createApi } from "@reduxjs/toolkit/query/react";
import { endpoints } from "consts";
import {
  Loyalty,
  LoyaltySetting,
  UpdateLoyaltyRequestDto,
} from "types/loyalty";
import { coreQuery } from "utils/baseQuery";

export const loyaltyAPI = createApi({
  reducerPath: "loyaltyAPI",
  baseQuery: coreQuery(),
  tagTypes: ["loyalty", "loyaltySettings"],
  endpoints: build => ({
    getLoyalty: build.query<Loyalty[], void>({
      query: () => ({
        url: endpoints.loyalty,
        method: "GET",
      }),
      providesTags: ["loyalty"],
    }),
    updateLoyalty: build.mutation<void, UpdateLoyaltyRequestDto>({
      query: ({ id, ...data }) => ({
        url: `${endpoints.loyalty}/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["loyalty"],
    }),
    getLoyaltySettings: build.query<LoyaltySetting, void>({
      query: () => ({
        url: `${endpoints.loyalty}/settings`,
        method: "GET",
      }),
      providesTags: ["loyaltySettings"],
    }),
    updateLoyaltySettings: build.mutation<LoyaltySetting, LoyaltySetting>({
      query: data => ({
        url: `${endpoints.loyalty}/settings`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["loyaltySettings"],
    }),
  }),
});

export const {
  useGetLoyaltyQuery,
  useUpdateLoyaltyMutation,
  useGetLoyaltySettingsQuery,
  useUpdateLoyaltySettingsMutation,
} = loyaltyAPI;
