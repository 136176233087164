// Lib
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useGetAuthPurposeSettingsListOptionsQuery } from "rtkQuery/query/authSettingsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Actions
// Selectors
// Types
import { SettingsListOption } from "types/authSettings";
import { TopUpBalanceForm } from "./types";
// Theme
// Constants
import { rtkQueryParams } from "consts";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { Modal } from "components";
import { InputNumber, Select, TextArea } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { resolver } from "./validation";

interface TopUpBalanceModalProps {
  open: boolean;
  isLoading: boolean;
  onSave: (args: TopUpBalanceForm) => void;
  onClose: () => void;
}

export const TopUpBalanceModal: FC<TopUpBalanceModalProps> = ({
  open,
  isLoading,
  onSave,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const { handleSubmit, control, reset } = useForm<TopUpBalanceForm>({
    resolver,
  });

  const {
    data: optionsData,
    isFetching: isOptionsDataLoading,
    error: optionsError,
  } = useGetAuthPurposeSettingsListOptionsQuery(
    { type: SettingsListOption.purpose },
    rtkQueryParams,
  );

  useEffect(() => {
    if (optionsError) {
      errorHandler({ error: optionsError, openNotification });
    }
  }, [optionsError]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const onSubmit: SubmitHandler<TopUpBalanceForm> = data => {
    onSave(data);
  };

  return (
    <Modal title="Top up balance" width={560} open={open} onClose={handleClose}>
      <FlexContainer
        $fullwidth
        $column
        $gap={10}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer $fullwidth $column $gap={16}>
          <Controller
            name="fmcCentsAmount"
            control={control}
            render={({ field, fieldState }) => (
              <InputNumber
                label="FM Coins"
                required
                placeholder="Enter amount coin"
                precision={3}
                {...field}
                disabled={isLoading}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="purpose"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                required
                label="Purpose"
                placeholder="Select purpose"
                {...field}
                disabled={isLoading}
                loading={isOptionsDataLoading}
                fieldState={fieldState}
                options={
                  optionsData?.map(({ name }) => ({
                    label: name,
                    value: name,
                  })) || []
                }
              />
            )}
          />

          <Controller
            name="note"
            control={control}
            render={({ field, fieldState }) => (
              <TextArea
                label="Note"
                placeholder="Enter Note"
                isDisabled={isLoading}
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-end"
          $gap={8}
          $margin="24px 0 0"
        >
          <FlexContainer
            $align="center"
            $justify="center"
            $gap={8}
            $column={isMobile}
            $fullwidth={isMobile}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Close
            </Button.Base>

            <Button.Base
              $fullWidth={isMobile}
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
