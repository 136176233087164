// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  AddAuthSettingsListOptionRequestDto,
  AuthSettingsResponseDto,
  AuthSettingsListOptionResponseDto,
  SetSettingsRequestDto,
  UpdateAuthSettingsListOptionRequestDto,
  SettingsListOption,
} from "types/authSettings";
// Utils
import { authQuery } from "utils/baseQuery";
import { authSettingsMapper, purposeOptionsMapper } from "utils/mappers";

import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";

export const authSettingsAPI = createApi({
  reducerPath: "authSettingsAPI",
  baseQuery: authQuery(),
  tagTypes: [
    "authSettings",
    "optionsPurposeListsSettings",
    "optionsServiceListsSettings",
  ],

  endpoints: build => ({
    getAuthSettings: build.query<AuthSettingsResponseDto, void>({
      query: () => ({
        url: endpoints.authSettings,
        method: "GET",
      }),
      providesTags: ["authSettings"],
      transformResponse: (response: AuthSettingsResponseDto) =>
        authSettingsMapper(response),
    }),
    updateAuthSettings: build.mutation<
      AuthSettingsResponseDto,
      SetSettingsRequestDto
    >({
      query: data => ({
        url: endpoints.authSettings,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["authSettings"],
    }),
    getAuthPurposeSettingsListOptions: build.query<
      AuthSettingsListOptionResponseDto[],
      { type: SettingsListOption }
    >({
      query: query => ({
        url: generateRequestUrl(`${endpoints.authSettings}/lists/`, {
          ...query,
        }),
        method: "GET",
      }),
      transformResponse: (response: AuthSettingsListOptionResponseDto[]) =>
        purposeOptionsMapper(response),
      providesTags: ["optionsPurposeListsSettings"],
    }),
    createAuthPurposeSettingsListOption: build.mutation<
      AuthSettingsListOptionResponseDto,
      AddAuthSettingsListOptionRequestDto
    >({
      query: data => ({
        url: `${endpoints.authSettings}/lists/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["optionsPurposeListsSettings"],
    }),
    updateAuthPurposeSettingsListOption: build.mutation<
      AuthSettingsListOptionResponseDto,
      UpdateAuthSettingsListOptionRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.authSettings}/lists/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["optionsPurposeListsSettings"],
    }),
    removeAuthPurposeSettingsListOption: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.authSettings}/lists/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["optionsPurposeListsSettings"],
    }),
    getAuthServiceSettingsListOptions: build.query<
      AuthSettingsListOptionResponseDto[],
      { type: SettingsListOption }
    >({
      query: query => ({
        url: generateRequestUrl(`${endpoints.authSettings}/lists/`, {
          ...query,
        }),
        method: "GET",
      }),
      transformResponse: (response: AuthSettingsListOptionResponseDto[]) =>
        purposeOptionsMapper(response),
      providesTags: ["optionsServiceListsSettings"],
    }),
    createAuthServiceSettingsListOption: build.mutation<
      AuthSettingsListOptionResponseDto,
      AddAuthSettingsListOptionRequestDto
    >({
      query: data => ({
        url: `${endpoints.authSettings}/lists/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["optionsServiceListsSettings"],
    }),
    updateAuthServiceSettingsListOption: build.mutation<
      AuthSettingsListOptionResponseDto,
      UpdateAuthSettingsListOptionRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.authSettings}/lists/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["optionsServiceListsSettings"],
    }),
    removeAuthServiceSettingsListOption: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.authSettings}/lists/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["optionsServiceListsSettings"],
    }),
  }),
});

export const {
  useGetAuthSettingsQuery,
  useLazyGetAuthSettingsQuery,
  useUpdateAuthSettingsMutation,
  useGetAuthPurposeSettingsListOptionsQuery,
  useCreateAuthPurposeSettingsListOptionMutation,
  useUpdateAuthPurposeSettingsListOptionMutation,
  useRemoveAuthPurposeSettingsListOptionMutation,
  useCreateAuthServiceSettingsListOptionMutation,
  useGetAuthServiceSettingsListOptionsQuery,
  useRemoveAuthServiceSettingsListOptionMutation,
  useUpdateAuthServiceSettingsListOptionMutation,
} = authSettingsAPI;
