// Lib
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Skeleton } from "antd";
// Api
import {
  useCreateFoodLibraryProductMutation,
  useGetFoodLibraryProductQuery,
  useUpdateFoodLibraryProductMutation,
} from "rtkQuery/query/foodLibraryAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { FoodLibraryProductForm } from "./types";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink } from "components";
import { Input, InputNumber } from "components/Form";
// Styled
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import {
  ContentBox,
  FlexContainer,
  InputsGridContainer,
  PageHeadingContainer,
  PageTitleContainer,
  PageWrapper,
} from "styled/Box";

import { resolver } from "./validation";
import { defaultValues } from "./defaultValues";

export const FoodLibraryProductDetails: FC = () => {
  const navigate = useNavigate();
  const { openNotification } = useNotification();
  const { id } = useParams();

  const isEdit = !!id;

  const [create, { isLoading: isCreateLoading }] =
    useCreateFoodLibraryProductMutation();
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateFoodLibraryProductMutation();

  const {
    data,
    isFetching: isProductGetLoading,
    error,
  } = useGetFoodLibraryProductQuery({ id }, { ...rtkQueryParams, skip: !id });

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const { handleSubmit, control, reset } = useForm<FoodLibraryProductForm>({
    resolver,
    defaultValues,
  });

  useEffect(() => {
    if (!isEdit) return;
    if (!data) return;

    reset({
      name: data?.name || "",
      calories: data?.calories || 0,
      carbs: data?.carbs || 0,
      fat: data?.fat || 0,
      protein: data?.protein || 0,
      servingSize: data?.servingSize || "",
      servingsNumber: data?.servingsNumber || 0,
      code: data?.code || "",
    });
  }, [data, reset, isEdit]);

  const onSubmit: SubmitHandler<FoodLibraryProductForm> = async data => {
    try {
      if (isEdit) {
        if (!id) return;

        await update({
          id,
          ...data,
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.PRODUCT_UPDATED });
      } else {
        await create(data).unwrap();

        openNotification({ message: NOTIFICATIONS.PRODUCT_CREATED });

        navigate(ADMIN_ROUTES.FOOD_LIBRARY.path);
      }
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const name = isEdit ? data?.name || "" : "New Product";

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $gap={24}
        $grow={1}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PageHeadingContainer>
          <PageTitleContainer $column>
            <ArrowBackLink
              title="Back to Food Library"
              path={ADMIN_ROUTES.FOOD_LIBRARY.path}
            />

            <FlexContainer
              $gap={12}
              $align="center"
              $justify="center"
              $margin="20px 0 0 0"
            >
              {isProductGetLoading ? (
                <>
                  <Skeleton.Input active size={"default"} />
                </>
              ) : name ? (
                <>
                  <Typography.H1>{name}</Typography.H1>
                </>
              ) : null}
            </FlexContainer>
          </PageTitleContainer>

          <FlexContainer $gap={8}>
            <Button.Heading
              type="primary"
              htmlType="submit"
              loading={isCreateLoading || isUpdateLoading}
            >
              Save
            </Button.Heading>
          </FlexContainer>
        </PageHeadingContainer>

        <ContentBox $column $gap={20}>
          <Typography.H2>Product Details</Typography.H2>

          <InputsGridContainer>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  label="Product name"
                  placeholder="Product name"
                  {...field}
                  fieldState={fieldState}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                />
              )}
            />

            <Controller
              name="code"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  label="Code"
                  placeholder="Code"
                  {...field}
                  fieldState={fieldState}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                />
              )}
            />

            <Controller
              name="calories"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Calories"
                  placeholder="Calories"
                  required
                  min={0}
                  step={1}
                  precision={0}
                  {...field}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="carbs"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Carbs"
                  placeholder="Carbs"
                  required
                  min={0}
                  step={1}
                  precision={0}
                  {...field}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="fat"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Fat"
                  placeholder="Fat"
                  required
                  min={0}
                  step={1}
                  precision={0}
                  {...field}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="protein"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Protein"
                  placeholder="Protein"
                  required
                  min={0}
                  step={1}
                  precision={0}
                  {...field}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="servingSize"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  required
                  label="Serving size"
                  placeholder="Serving size"
                  {...field}
                  fieldState={fieldState}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                />
              )}
            />

            <Controller
              name="servingsNumber"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Servings number"
                  placeholder="Servings number"
                  required
                  min={0}
                  step={1}
                  precision={0}
                  {...field}
                  disabled={
                    isProductGetLoading || isUpdateLoading || isCreateLoading
                  }
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>
      </FlexContainer>
    </PageWrapper>
  );
};
