import { Permission } from "consts/permissions";
// Types
import { SidebarMenuItemsType } from "types/common";
// Icons
import {
  AccountBalanceWalletIcon,
  CouponIcon,
  GroupIcon,
  ManageAccountsIcon,
  MapIcon,
  MenuIcon,
  MonetizationIcon,
  OrdersIcon,
  PersonIcon,
  ServerIcon,
  SettingsIcon,
  RateStarIcon,
  ProductIcon,
} from "icons";

export const sidebarMenuItems: SidebarMenuItemsType = {
  admin: [
    {
      key: "orders",
      Icon: OrdersIcon,
      label: "Orders",
      path: "/orders",
      type: "link",
      access: [Permission.OrdersGet],
    },
    {
      key: "menu",
      Icon: MenuIcon,
      label: "Menu",
      type: "list",
      list: [
        {
          key: "recommended",
          label: "Recommended",
          path: "/menu/recommended",
          access: [Permission.LocationsMenuGet],
        },
      ],
    },
    {
      key: "food-library",
      Icon: ProductIcon,
      label: "Food Library",
      path: "/food-library",
      type: "link",
      access: true,
    },
    // {
    //   Icon: AnalyticIcon,
    //   label: "Analytics",
    //   path: "/analytics",
    //   type: "link",
    //   access: true,
    // },
    {
      key: "customers",
      Icon: PersonIcon,
      label: "Customers",
      path: "/customers",
      type: "link",
      access: [Permission.CustomersGet],
    },
    {
      key: "reviews",
      Icon: RateStarIcon,
      label: "Rates and Reviews",
      path: "/reviews",
      type: "link",
      access: true,
    },
    {
      key: "coin-packages",
      Icon: MonetizationIcon,
      label: "Coin Packages",
      path: "/coin-packages",
      type: "link",
      access: [Permission.PackagesGet],
    },

    {
      key: "coupons",
      Icon: CouponIcon,
      label: "Coupons",
      type: "list",
      access: [Permission.CouponsGet],
      list: [
        {
          key: "order-coupons",
          label: "Purchase Coupons",
          path: "/coupons/order-coupons",
          access: [Permission.CouponsGet],
        },
        {
          key: "top-up-coupons",
          label: "Top-up Coupons",
          path: "/coupons/top-up-coupons",
          access: [Permission.CouponsGet],
        },
      ],
    },
    {
      key: "transactions",
      Icon: AccountBalanceWalletIcon,
      label: "Transactions",
      path: "/transactions",
      type: "link",
      access: [Permission.TransactionsGet],
    },
    {
      key: "users",
      Icon: GroupIcon,
      label: "Users",
      type: "list",
      list: [
        {
          key: "all-users",
          label: "All Users",
          path: "/users/all-users",
          access: [Permission.UsersGet],
        },
        {
          key: "waiting-list",
          label: "Waiting List",
          path: "/users/waiting-list",
          access: [Permission.UsersGet],
        },
      ],
    },
    {
      key: "roles",
      Icon: ManageAccountsIcon,
      label: "Roles",
      path: "/roles",
      type: "link",
      access: [Permission.RolesGet],
    },
    {
      key: "delivery-areas",
      Icon: MapIcon,
      label: "Delivery Areas",
      path: "/delivery-areas",
      type: "link",
      access: [Permission.LocationsGet],
    },

    {
      key: "settings",
      Icon: SettingsIcon,
      label: "Settings",
      path: "/settings",
      type: "link",
      access: [Permission.SettingsGet],
    },
    {
      key: "location-providers",
      Icon: ServerIcon,
      label: "Location Providers",
      path: "/location-providers",
      type: "link",
      access: [Permission.LocationProvidersGet],
    },
  ],
};
