// Lib
import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetAuthServiceSettingsListOptionsQuery } from "rtkQuery/query/authSettingsAPI";
// Hooks
import { useNotification, useTable } from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { ETable } from "types/tableFilters";
import { SettingsListOption } from "types/authSettings";
import { GetAggregatorOrdersResponseDto, OrderFilterTypes } from "types/orders";
// Icons
import { DescriptionBlackIcon } from "icons";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Table } from "components";
// Styled

import { columns, filtersList } from "./config";

interface AggregatorTabProps {
  isLoading: boolean;
  isFetching: boolean;
  isDesktop: boolean;
  ordersData: GetAggregatorOrdersResponseDto;
  locationOptions: {
    label: string;
    type: string;
  }[];
  handleOrdersSort: (field: string, direction: string) => void;
}

export const AggregatorTab: FC<AggregatorTabProps> = memo(
  ({ isLoading, isDesktop, ordersData, locationOptions, handleOrdersSort }) => {
    const { openNotification } = useNotification();
    const navigate = useNavigate();

    const { selectedFilters, handleSetTableFilterValues } = useTable({
      name: ETable.AggregatorOrders,
      removeQueryParams: true,
    });

    const { data: serviceOptions, error: serviceOptionsError } =
      useGetAuthServiceSettingsListOptionsQuery({
        type: SettingsListOption.service,
      });

    useEffect(() => {
      if (serviceOptionsError) {
        errorHandler({ error: serviceOptionsError, openNotification });
      }
    }, [serviceOptionsError]);

    const handleTableChange = useCallback(
      (
        _pagination: TablePaginationConfig,
        _filters: Record<string, FilterValue>,
        sorter: SorterResult<GetAggregatorOrdersResponseDto>,
      ) => {
        handleOrdersSort(sorter?.field as string, sorter?.order);
      },
      [],
    );

    const onRow = useCallback(record => {
      return {
        onClick: () => navigate(`/orders/aggregator/${record.id}`),
      };
    }, []);

    const filters = useMemo(() => {
      let filters = filtersList;

      if (locationOptions?.length) {
        filters = filtersList?.map(filter => {
          if (filter.key === OrderFilterTypes.locationId) {
            return { ...filter, options: locationOptions };
          }

          return filter;
        });
      }

      if (serviceOptions?.length) {
        filters = filtersList?.map(filter => {
          if (filter.key === OrderFilterTypes.service) {
            return {
              ...filter,
              options: serviceOptions?.map(service => ({
                label: service.name,
                type: service.name.toLowerCase(),
              })),
            };
          }

          return filter;
        });
      }
      return filters;
    }, [filtersList, locationOptions]);

    return (
      <Table
        isLoading={isLoading}
        dataSource={ordersData?.items || []}
        withPagination={!isDesktop}
        columns={columns}
        empty={{
          icon: DescriptionBlackIcon,
          title: "No order to show.",
          description: "Try changing sections or change the filters",
        }}
        header={{
          totalCount: ordersData?.totalCount,
          filter: {
            filters: filters,
            selected: selectedFilters,
            setValue: handleSetTableFilterValues,
          },
        }}
        onChange={handleTableChange}
        onRow={onRow}
      />
    );
  },
);

AggregatorTab.displayName = "AggregatorTab";
