// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useGetCustomerMenuByLocationQuery } from "rtkQuery/query/customersAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
import { CustomerMenuProduct } from "types/customers";
// Helpers
import { isMatch } from "helpers/dataHelpers";
import { transformToTableData } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Pagination, Table } from "components";
import { Modal } from "components/Modal";
import { Select } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { columns, mobileColumns } from "./config";

interface ProductsModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (product: CustomerMenuProduct) => void;
}

export const ProductsModal: FC<ProductsModalProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const { isMobile, isLargeMobile, isDesktop } = useViewport();

  const { openNotification } = useNotification();

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    search,
    setPage,
    setLimit,
    setSearch,
    handleSort,
  } = useTable({
    name: ETable.ProductsModal,
    removeQueryParams: true,
  });

  const [selectedLocation, setSelectedLocation] = useState<
    string | undefined
  >();

  const [sortedData, setSortedData] = useState<CustomerMenuProduct[]>([]);

  const {
    data: locations,
    isLoading: isLocationsLoading,
    error: locationsError,
  } = useGetLocationsQuery(null, { skip: !open });

  const {
    data: menu,
    isLoading: isMenuLoading,
    error: menuError,
  } = useGetCustomerMenuByLocationQuery(
    {
      id: selectedLocation || "",
    },
    { skip: !selectedLocation },
  );

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  useEffect(() => {
    if (menuError) {
      errorHandler({ error: menuError, openNotification });
    }
  }, [menuError]);

  useEffect(() => {
    if (!menu) return;

    if (
      !sortingOrder &&
      !search &&
      !isMatch(transformToTableData(menu), sortedData)
    ) {
      setSortedData(transformToTableData(menu));
      return;
    }
    const sortedArray = [...transformToTableData(menu)]
      .sort((a, b) => {
        const valA = a[sortBy];
        const valB = b[sortBy];
        if (valA < valB) return sortingOrder === "ASC" ? -1 : 1;
        if (valA > valB) return sortingOrder === "ASC" ? 1 : -1;
        return 0;
      })
      .filter(el =>
        search ? el.name.toLowerCase().includes(search.toLowerCase()) : el,
      );
    setSortedData(sortedArray);
  }, [menu, search, sortBy, sortingOrder]);

  const onRow = (record: CustomerMenuProduct) => {
    return {
      onClick: () => onSelect(record),
    };
  };

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const paginate = (
    array: CustomerMenuProduct[],
    currentPage: number,
    pageSize: number,
  ) => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return array.slice(start, end);
  };

  const paginatedData = paginate(sortedData, page, limit);

  const locationsOptions =
    locations?.map(loc => ({ value: loc.id, label: loc?.name })) || [];

  return (
    <Modal title="Products" open={open} width={1160} onClose={onClose}>
      <FlexContainer $fullwidth $column $gap={20}>
        <FlexContainer $fullwidth $justify="flex-end">
          <Select
            style={{ maxWidth: 200, minWidth: 200 }}
            label="Location menu"
            placeholder="Select location menu"
            value={selectedLocation}
            options={locationsOptions}
            onChange={(value: string) => setSelectedLocation(value)}
          />
        </FlexContainer>

        <Table
          header={{ search: { setValue: setSearch, value: search } }}
          columns={!isDesktop ? mobileColumns : columns}
          isLoading={isLocationsLoading || isMenuLoading}
          dataSource={paginatedData}
          scroll={{ y: 488, x: isLargeMobile && "150vw" }}
          onChange={handleTableChange}
          onRow={onRow}
        />

        <Pagination
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          totalItems={sortedData.length}
        />
      </FlexContainer>

      <FlexContainer
        $fullwidth
        $column={isMobile}
        $justify="flex-end"
        $gap={8}
        $padding="24px 0 0"
      >
        <Button.Heading $fullWidth={isMobile} onClick={onClose}>
          Cancel
        </Button.Heading>
      </FlexContainer>
    </Modal>
  );
};
