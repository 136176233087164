export enum TabKey {
  General = "general",
  Scheduled = "scheduled",
  Aggregator = "aggregators",
}

export const tabTitle = {
  [TabKey.General]: "General",
  [TabKey.Scheduled]: "Scheduled",
  [TabKey.Aggregator]: "Aggregator Orders",
};

export const pageSizeOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 500,
    label: "500",
  },
];
