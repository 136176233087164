import {
  CustomerMenuProduct,
  GetCustomerMenuResponseDto,
} from "types/customers";

export const transformToTableData = (
  data: GetCustomerMenuResponseDto,
): CustomerMenuProduct[] => {
  return data?.categories?.flatMap(category => category.products);
};
