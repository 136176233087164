import { NOTIFICATIONS } from "consts/notifications";
import { LoyaltyBonus } from "types/loyalty";

export enum LoyaltyTiersField {
  IsLoyaltyFeatureEnabled = "isLoyaltyFeatureEnabled",
}

export type ConfirmModalType = {
  key: LoyaltyTiersField;
  value: boolean;
};

export const notificationMessage = {
  [LoyaltyTiersField.IsLoyaltyFeatureEnabled]:
    NOTIFICATIONS.IS_APP_CONFIG_UPDATED,
};

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Discounts (%)",
    dataIndex: "discountPercentage",
    key: "discountPercentage",
    align: "right" as const,
  },
  {
    title: "Free days",
    dataIndex: "freeDeliveryDays",
    key: "freeDeliveryDays",
    align: "right" as const,
  },
  {
    title: "Free items",
    dataIndex: "bonus",
    key: "bonus",
    align: "right" as const,
    render: (bonus: LoyaltyBonus) => bonus?.title || "",
  },
];
