// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  CreateFoodLibraryProductRequestDto,
  FoodLibraryProduct,
  GetFoodLibraryResponseDto,
  GetFoodLibrarySettingsResponseDto,
  UpdateFoodLibraryProductRequestDto,
  UpdateFoodLibrarySettingsRequestDto,
} from "types/foodLibrary";
import { UrlQueryParams } from "types/common";
// Helpers
import { generateRequestUrl } from "helpers/dataHelpers";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";

export const foodLibraryAPI = createApi({
  reducerPath: "foodLibraryAPI",
  baseQuery: coreQuery(),
  tagTypes: ["foodLibrary", "foodLibrary-setting"],

  endpoints: build => ({
    getFoodLibrary: build.query<
      GetFoodLibraryResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => ({
        url: generateRequestUrl(endpoints.foodLibrary, query),
        method: "GET",
      }),
      providesTags: ["foodLibrary"],
    }),
    getFoodLibraryProduct: build.query<FoodLibraryProduct, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.foodLibrary}/${id}`,
        method: "GET",
      }),
      providesTags: ["foodLibrary"],
    }),
    createFoodLibraryProduct: build.mutation<
      void,
      CreateFoodLibraryProductRequestDto
    >({
      query: data => ({
        url: `${endpoints.foodLibrary}`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["foodLibrary"],
    }),
    updateFoodLibraryProduct: build.mutation<
      void,
      UpdateFoodLibraryProductRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.foodLibrary}/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["foodLibrary"],
    }),
    deleteFoodLibraryProduct: build.mutation<
      GetFoodLibraryResponseDto,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${endpoints.foodLibrary}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["foodLibrary"],
    }),
    getFoodLibrarySettings: build.query<
      GetFoodLibrarySettingsResponseDto,
      void | null
    >({
      query: () => ({
        url: `${endpoints.foodLibrary}/settings`,
        method: "GET",
      }),
      providesTags: ["foodLibrary-setting"],
    }),
    updateFoodLibrarySettings: build.mutation<
      GetFoodLibrarySettingsResponseDto,
      UpdateFoodLibrarySettingsRequestDto
    >({
      query: data => ({
        url: `${endpoints.foodLibrary}/settings`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["foodLibrary-setting"],
    }),
  }),
});

export const {
  useGetFoodLibraryQuery,
  useGetFoodLibraryProductQuery,
  useCreateFoodLibraryProductMutation,
  useUpdateFoodLibraryProductMutation,
  useDeleteFoodLibraryProductMutation,
  useUpdateFoodLibrarySettingsMutation,
} = foodLibraryAPI;
