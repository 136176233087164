export enum FoodLibraryProductFilterTypes {
  name = "name",
  code = "code",
}

export enum FoodLibraryProductFilterNames {
  name = "Product name",
  code = "Product code",
}

export type FoodLibraryProduct = {
  id: string;
  createdAt: string;
  updatedAt: string;
  calories: number;
  carbs: number;
  fat: number;
  protein: number;
  servingSize: string;
  servingsNumber: number;
  code: string;
  name: string;
};

export type GetFoodLibraryResponseDto = {
  items: FoodLibraryProduct[];
  totalCount: number;
};

export type CreateFoodLibraryProductRequestDto = Omit<
  FoodLibraryProduct,
  "id" | "createdAt" | "updatedAt"
>;

export type UpdateFoodLibraryProductRequestDto = Omit<
  FoodLibraryProduct,
  "createdAt" | "updatedAt"
>;

export type GetFoodLibrarySettingsResponseDto = {
  isFoodLibraryEnabled: boolean;
};

export type UpdateFoodLibrarySettingsRequestDto = {
  isFoodLibraryEnabled: boolean;
};
