// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useGetLoyaltyQuery,
  useGetLoyaltySettingsQuery,
  useUpdateLoyaltySettingsMutation,
} from "rtkQuery/query/loyaltyAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { TableAction } from "types/common";
import { Loyalty } from "types/loyalty";
// Constants
import { rtkQueryParams } from "consts";
// Helpers
import { getConfirmModalTitle } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PersonIcon } from "icons";
// Components
import { Table } from "components";
import { ConfirmDialog } from "components/Modals";
import SettingsItem from "../SettingsItem";
import { Switch } from "../Inputs";
import { LoyaltyTierEditModal } from "../LoyaltyTierEditModal";
// Styled
import { ContentBox, SubContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

import {
  columns,
  ConfirmModalType,
  LoyaltyTiersField,
  notificationMessage,
} from "./config";

export const LoyaltyTiersSettings: FC = () => {
  const { openNotification } = useNotification();

  const [checked, setChecked] = useState<boolean | undefined>();
  const [tierModal, setTierModal] = useState<Loyalty | false>(false);

  const [update, { isLoading }] = useUpdateLoyaltySettingsMutation();

  const {
    data: tiersSettings,
    isFetching: isTiersSettingsFetching,
    error: tiersSettingsError,
  } = useGetLoyaltySettingsQuery(null, rtkQueryParams);

  const {
    data: tiers,
    isFetching: isFetchingTiers,
    error: errorTiers,
  } = useGetLoyaltyQuery(null, rtkQueryParams);

  const [confirmModal, setConfirmModal] = useState<ConfirmModalType | false>(
    false,
  );

  useEffect(() => {
    if (tiersSettings) {
      setChecked(tiersSettings.isLoyaltyFeatureEnabled);
    }
  }, [tiersSettings]);

  useEffect(() => {
    if (errorTiers) {
      errorHandler({ error: errorTiers, openNotification });
    }
  }, [errorTiers]);

  useEffect(() => {
    if (tiersSettingsError) {
      errorHandler({ error: tiersSettingsError, openNotification });
    }
  }, [tiersSettingsError]);

  const handleUpdateBooleanUpdate = (
    key: LoyaltyTiersField,
    value: boolean,
  ) => {
    setChecked(value);
    setConfirmModal({ key, value });
  };

  const onCancel = () => {
    if (!confirmModal) return;

    setChecked(prev => !prev);
    setConfirmModal(false);
  };

  const onConfirm = async () => {
    if (!confirmModal) return;

    try {
      await update({
        [confirmModal.key]: confirmModal.value,
      }).unwrap();

      openNotification({ message: notificationMessage[confirmModal.key] });

      setConfirmModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      type: "Grey",
      loadingId: "0",
      onClick: row => setTierModal(row),
    },
  ];

  return (
    <>
      <ContentBox $column $gap={12}>
        <Typography.H2>Loyalty Tier System</Typography.H2>

        <SettingsItem title="Enable loyalty tier system">
          <Switch
            isLoading={isTiersSettingsFetching}
            canUpdate={true}
            data={checked}
            onChange={value =>
              handleUpdateBooleanUpdate(
                LoyaltyTiersField.IsLoyaltyFeatureEnabled,
                value,
              )
            }
          />
        </SettingsItem>

        <SubContentBox
          $column
          $padding="0px"
          $margin="0 -12px 0 -12px"
          $width="auto"
        >
          <Table
            shadow={false}
            loading={isFetchingTiers}
            columns={columns}
            header={{
              totalCount: tiers?.length || 0,
            }}
            actions={actions}
            dataSource={tiers || []}
          />
        </SubContentBox>
      </ContentBox>

      <ConfirmDialog
        isLoading={confirmModal && isLoading}
        open={!!confirmModal}
        Icon={PersonIcon}
        message={
          confirmModal &&
          getConfirmModalTitle({
            key: confirmModal.key,
            value: confirmModal.value,
          })
        }
        description="Are you sure to continue this action?"
        onCancel={onCancel}
        firstCTAButton={{
          title: "Accept",
          status: "danger",
          disabled: false,
          loading: confirmModal && isLoading,
          onClick: onConfirm,
        }}
      />

      <LoyaltyTierEditModal
        withBonus
        data={tierModal}
        onClose={() => setTierModal(false)}
      />
    </>
  );
};
