// Lib
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useUpdateLoyaltyMutation } from "rtkQuery/query/loyaltyAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { CustomerMenuProduct } from "types/customers";
import { LoyaltyTierEditForm, LoyaltyTierEditModalProps } from "./types";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Modal, ProductItem } from "components";
import { Input, InputNumber, TextArea } from "components/Form";
import { ProductsModal } from "components/Modals";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { resolver } from "./validation";
import { theme } from "theme";

export const LoyaltyTierEditModal: FC<LoyaltyTierEditModalProps> = ({
  withBonus,
  data,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const [isProductsModal, setIsProductsModal] = useState<boolean>(false);

  const [update, { isLoading }] = useUpdateLoyaltyMutation();

  const { handleSubmit, control, reset, watch, setValue } =
    useForm<LoyaltyTierEditForm>({
      resolver,
    });

  useEffect(() => {
    if (!data) return;

    reset(data);
  }, [data]);

  const onProductSelect = (product: CustomerMenuProduct) => {
    setValue("bonus", {
      title: product?.name,
      description: product?.description || "",
      imageUrl: product?.imageURL || "",
      providerId: product?.providerId || "",
    });

    setIsProductsModal(false);
  };

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const onSubmit: SubmitHandler<LoyaltyTierEditForm> = async formData => {
    if (!data) return;

    try {
      await update({
        id: data.id,
        ...formData,
      }).unwrap();

      handleClose();
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const bonus = watch("bonus");

  const removeBonus = () => {
    setValue("bonus.title", "");
    setValue("bonus.description", "");
    setValue("bonus", null);
  };

  return (
    <>
      <Modal open={!!data} title={data ? data?.name : ""} onClose={handleClose}>
        <FlexContainer
          $fullwidth
          $column
          $gap={16}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputsGridContainer>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <Input label="Name" {...field} fieldState={fieldState} />
              )}
            />

            <Controller
              name="maintainAmount"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  min={0}
                  label="Maintain amount"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="goalLevelPoints"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  min={0}
                  label="Goal level points"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
            <Controller
              name="pointsAmountForOrder"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  min={0}
                  label="Points per 1 KD"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
            <Controller
              name="discountPercentage"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  min={0}
                  label="Discount (%)"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
            <Controller
              name="freeDeliveryDays"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  min={0}
                  label="Free delivery days"
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>

          {withBonus && bonus?.providerId ? (
            <ProductItem
              name={bonus?.title}
              imageURL={bonus?.imageUrl}
              content={
                <FlexContainer $fullwidth $column $gap={8}>
                  <Controller
                    name="bonus.title"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input label="Title" {...field} fieldState={fieldState} />
                    )}
                  />
                  <Controller
                    name="bonus.description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextArea
                        label="Description"
                        {...field}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </FlexContainer>
              }
            >
              <FlexContainer $gap={8}>
                <Button.SquaredIcon
                  icon={<PencilIcon />}
                  onClick={() => setIsProductsModal(true)}
                />

                <Button.SquaredIcon
                  icon={<TrashIcon fill={theme.color.red} />}
                  onClick={() => removeBonus()}
                />
              </FlexContainer>
            </ProductItem>
          ) : (
            <Button.Base
              icon={<PlusIcon />}
              onClick={() => setIsProductsModal(true)}
            >
              Add Product
            </Button.Base>
          )}

          <FlexContainer
            $fullwidth
            $column={isMobile}
            $align={isMobile ? "flex-start" : "center"}
            $justify={isMobile ? "flex-start" : "flex-end"}
            $gap={isMobile ? 16 : 8}
            $margin="24px 0 0"
          >
            <FlexContainer
              $fullwidth={isMobile}
              $column={isMobile}
              $align="center"
              $justify="center"
              $gap={8}
            >
              <Button.Base
                $fullWidth={isMobile}
                disabled={isLoading}
                onClick={handleClose}
              >
                Close
              </Button.Base>

              <Button.Base
                $fullWidth={isMobile}
                loading={isLoading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button.Base>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Modal>

      <ProductsModal
        open={isProductsModal}
        onClose={() => setIsProductsModal(false)}
        onSelect={onProductSelect}
      />
    </>
  );
};
