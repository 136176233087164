import { yupResolver } from "@hookform/resolvers/yup";
import { number, object, string } from "yup";

const schema = object({
  name: string().nullable().required(),
  description: string().nullable().optional(),
  goalLevelPoints: number().nullable().required(),
  pointsAmountForOrder: number().nullable().required(),
  maintainAmount: number().nullable().required(),
  discountPercentage: number().nullable().required(),
  freeDeliveryDays: number().nullable().required(),
  bonus: object({
    providerId: string(),
    title: string().when("providerId", {
      is: (value: string | null) => !!value,
      then: schema => schema.required("Title is required"),
      otherwise: schema => schema.nullable(),
    }),
    description: string(),
    imageUrl: string(),
  }).nullable(),
})
  .nullable()
  .required();

export const resolver = yupResolver(schema);
