// Lib
import { FC, memo, useEffect } from "react";
// Api
import { useGetAuthServiceSettingsListOptionsQuery } from "rtkQuery/query/authSettingsAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { SettingsListOption } from "types/authSettings";
import { CustomerData } from "../../types";
// Constants
import { PHONE_CODES, rtkQueryParams } from "consts";
// Components
import { Input, PhoneInput, Select } from "components/Form";
// Utils
import { errorHandler } from "utils/errorHandler";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface InformationsProps {
  orderIdError: string;
  customerData: CustomerData;
  setCustomerData: React.Dispatch<React.SetStateAction<CustomerData>>;
}

export const Informations: FC<InformationsProps> = memo(
  ({ orderIdError, customerData, setCustomerData }) => {
    const { openNotification } = useNotification();

    const { orderId, hashTag, phone, phoneCode, firstName, service } =
      customerData;

    const {
      data: serviceOptions,
      isFetching: isServiceOptionsFetching,
      error: serviceOptionsError,
    } = useGetAuthServiceSettingsListOptionsQuery(
      {
        type: SettingsListOption.service,
      },
      rtkQueryParams,
    );

    useEffect(() => {
      if (serviceOptionsError) {
        errorHandler({ error: serviceOptionsError, openNotification });
      }
    }, [serviceOptionsError]);

    const handleInputChange = (key: keyof CustomerData, value: string) => {
      setCustomerData(prev => ({ ...prev, [key]: value }));
    };

    const services = serviceOptions.map(service => ({
      label: service.name,
      value: service.name,
    }));

    return (
      <ContentBox $column $gap={16}>
        <Typography.H2>Informations</Typography.H2>

        <FlexContainer $column $gap={8}>
          <InputsGridContainer>
            <Select
              label="Order Source"
              value={service}
              loading={isServiceOptionsFetching}
              options={services}
              onChange={value => handleInputChange("service", value)}
            />

            <Input
              label="Order ID"
              placeholder="Order ID"
              value={orderId}
              onChange={e => handleInputChange("orderId", e.target.value)}
              fieldError={orderIdError}
            />

            <Input
              label="Hashtag number"
              placeholder="Hashtag number"
              value={hashTag}
              onChange={e => handleInputChange("hashTag", e.target.value)}
            />

            <Input
              required
              label="Customer name"
              placeholder="Customer name"
              value={firstName}
              onChange={e => handleInputChange("firstName", e.target.value)}
            />

            <PhoneInput
              required
              label="Phone"
              phonePlaceholder="Enter phone number"
              phone={phone}
              phoneCode={phoneCode}
              phoneCodes={PHONE_CODES}
              setPhone={phone => handleInputChange("phone", phone)}
              setPhoneCode={phoneCode =>
                handleInputChange("phoneCode", phoneCode)
              }
            />
          </InputsGridContainer>
        </FlexContainer>
      </ContentBox>
    );
  },
);

Informations.displayName = "Informations";
