import { generateRequestUrl } from "helpers/dataHelpers";
// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  CreateOrderDeliveryManualRequestDto,
  CreateOrderDeliveryManualResponseDto,
  CreateOrderRequestDto,
  CreateOrderResponseDto,
  CreateAggregatorOrderRequestDto,
  GetOrdersResponseDto,
  GetScheduledOrdersResponseDto,
  GetAggregatorOrdersResponseDto,
  OrderResponseDto,
  ScheduledOrderResponseDto,
  ScheduleOrdersSettingsDto,
  AggregatorOrderResponseDto,
} from "types/orders";
// Helpers
import { ordersUrlBuilder } from "./urlBuilder/orders";
// Utils
import { coreQuery } from "utils/baseQuery";
import {
  createOrderPayloadMapper,
  createAggregatorOrderPayloadMapper,
  orderMapper,
  ordersMapper,
  scheduledOrderMapper,
  scheduledOrdersMapper,
  aggregatorOrderMapper,
  aggregatorOrdersMapper,
} from "utils/mappers";

import { endpoints } from "consts";

export const ordersAPI = createApi({
  reducerPath: "ordersAPI",
  baseQuery: coreQuery(),
  tagTypes: [
    "orders",
    "order",
    "schedule-orders-settings",
    "aggregator-orders",
  ],

  endpoints: build => ({
    getOrders: build.query<GetOrdersResponseDto, { query: UrlQueryParams }>({
      query: ({ query }) => {
        return {
          url: ordersUrlBuilder.getOrders({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetOrdersResponseDto) =>
        ordersMapper(response),
      providesTags: ["orders"],
    }),
    getAggregatorOrders: build.query<
      GetAggregatorOrdersResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => {
        return {
          url: ordersUrlBuilder.getAggregatorOrders({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetAggregatorOrdersResponseDto) =>
        aggregatorOrdersMapper(response),
      providesTags: ["aggregator-orders"],
    }),
    getScheduledOrders: build.query<
      GetScheduledOrdersResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => {
        return {
          url: ordersUrlBuilder.getScheduledOrders({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetScheduledOrdersResponseDto) =>
        scheduledOrdersMapper(response),
      providesTags: ["orders"],
    }),
    getOrder: build.query<OrderResponseDto, { id: string }>({
      query: ({ id }) => {
        return {
          url: ordersUrlBuilder.getOrder({ id }),
          method: "GET",
        };
      },
      transformResponse: (response: OrderResponseDto) => orderMapper(response),
      providesTags: ["order"],
    }),
    getAggregatorOrder: build.query<AggregatorOrderResponseDto, { id: string }>(
      {
        query: ({ id }) => {
          return {
            url: ordersUrlBuilder.getAggregatorOrder({ id }),
            method: "GET",
          };
        },
        transformResponse: (response: AggregatorOrderResponseDto) =>
          aggregatorOrderMapper(response),
        providesTags: ["order"],
      },
    ),
    getScheduledOrder: build.query<ScheduledOrderResponseDto, { id: string }>({
      query: ({ id }) => {
        return {
          url: ordersUrlBuilder.getScheduledOrder({ id }),
          method: "GET",
        };
      },
      transformResponse: (response: ScheduledOrderResponseDto) =>
        scheduledOrderMapper(response),
      providesTags: ["order"],
    }),
    getScheduleOrdersSettings: build.query<ScheduleOrdersSettingsDto, void>({
      query: () => ({
        url: `${endpoints.scheduleOrders}/settings`,
        method: "GET",
      }),
      providesTags: ["schedule-orders-settings"],
    }),
    updateScheduleOrdersSettings: build.mutation<
      ScheduleOrdersSettingsDto,
      ScheduleOrdersSettingsDto
    >({
      query: data => ({
        url: `${endpoints.scheduleOrders}/settings`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["schedule-orders-settings"],
    }),
    createOrderDeliveryManual: build.mutation<
      CreateOrderDeliveryManualResponseDto,
      CreateOrderDeliveryManualRequestDto
    >({
      query: ({ customerId, ...data }) => ({
        url: generateRequestUrl(endpoints.deliveriesManual, { customerId }),
        method: "POST",
        data,
      }),
    }),
    createOrder: build.mutation<CreateOrderResponseDto, CreateOrderRequestDto>({
      query: ({ customerId, ...data }) => ({
        url: generateRequestUrl(endpoints.orders, { customerId }),
        method: "POST",
        data: createOrderPayloadMapper(data),
      }),
    }),
    createAggregatorOrder: build.mutation<
      CreateOrderResponseDto,
      CreateAggregatorOrderRequestDto
    >({
      query: data => ({
        url: `${endpoints.orders}/external`,
        method: "POST",
        data: createAggregatorOrderPayloadMapper(data),
      }),
    }),
    validateAggregatorOrderId: build.query<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.orders}/external/${id}/validate`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetAggregatorOrdersQuery,
  useLazyGetOrdersQuery,
  useLazyGetAggregatorOrdersQuery,
  useGetScheduledOrdersQuery,
  useLazyGetScheduledOrdersQuery,
  useGetOrderQuery,
  useGetAggregatorOrderQuery,
  useGetScheduledOrderQuery,
  useLazyGetScheduleOrdersSettingsQuery,
  useUpdateScheduleOrdersSettingsMutation,
  useCreateOrderMutation,
  useCreateOrderDeliveryManualMutation,
  useCreateAggregatorOrderMutation,
  useLazyValidateAggregatorOrderIdQuery,
} = ordersAPI;
