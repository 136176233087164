import { TABLE_DATE } from "consts";
import { dateTransform } from "helpers/dataHelpers";

import { getCouponDiscountLabel } from "pages/Coupons/helpers";

import {
  Coupon,
  CouponType,
  CouponTypeLabel,
  DiscountType,
  DiscountTypeLabel,
} from "types/coupons";

export const columns = [
  {
    title: "Coupon code",
    dataIndex: "couponCode",
    key: "couponCode",
  },

  {
    title: "Discount",
    dataIndex: "discountOff",
    key: "discountOff",
    render: (value: number, record: Coupon) =>
      getCouponDiscountLabel(value, record),
  },
  {
    title: "Discount type",
    dataIndex: "discountType",
    key: "discountType",
    render: (value: DiscountType) => DiscountTypeLabel[value] || value,
  },
  {
    title: "Coupon type",
    dataIndex: "couponType",
    key: "couponType",
    render: (value: CouponType) => CouponTypeLabel[value] || value,
  },
  {
    title: "Order number",
    dataIndex: "orderSid",
    key: "orderSid",
  },
  {
    title: "Coupon used",
    dataIndex: "couponUsed",
    key: "createdAt",
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];
